import { storage, db, auth } from "../firebase";
import { signOut, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { ref, uploadString } from "firebase/storage";
import { startAt, endAt, orderBy, collection, query, where, setDoc, getDocs, doc, getDoc, updateDoc, addDoc, deleteDoc, limit } from "firebase/firestore";

// Generic Firebase functions
// Get Docs
export const getFire = function (payload) {
    return new Promise(async (resolve, reject) => {
        const docRef = doc(db, payload.collection, payload.doc);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            resolve({ ...docSnap.data(), id: docSnap.id })
        } else {
            reject()
        }
    })
}

// GEO FIRE Search Docs
export const geoFireUser = function (payload) {
    return new Promise(async (resolve, reject) => {
        console.log("payload.startAt", payload.startAt)
        try{
            const q = query(collection(db,"users"),
            where("geohash", ">=", payload.startAt),
            where("geohash", "<=", payload.endAt),
            orderBy("geohash"));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

// Put Docs
export const putFire = function (payload) {
    return new Promise(async (resolve, reject) => {
        try {
            const docRef = doc(db, payload.collection, payload.doc);
            await updateDoc(docRef, payload.body);
            resolve({ ...payload.body, id: docRef.id })
        } catch (e) {
            reject(e)
        }
    })
}

// Post Docs
export const postFire = function (payload) {
    return new Promise(async (resolve, reject) => {
        try {
            const docRef = await addDoc(collection(db, payload.collection), payload.body);
            resolve({ ...payload.body, id: docRef.id })
        } catch (e) {
            reject(e)
        }
    })
}

export const setFire = function (payload) {
    console.log("utils payload", payload)
    return new Promise(async (resolve, reject) => {
        try {
            await setDoc(doc(db, payload.collection, payload.doc), payload.body, {merge: true});
            resolve({...payload.body, id: payload.doc})
        } catch (e) {
            reject(e)
        }
    })
}

export const setFiles = function (payload) {
    console.log("utils payload", payload)
    return new Promise(async (resolve, reject) => {
        try {
            const docRef = await addDoc(collection(db, "customers", payload.customerId, "files"), payload.body);
            resolve({...payload.body, id: docRef.id})
        } catch (e) {
            reject(e)
        }
    })
}

// Delete Docs
export const deleteFire = function (payload) {
    return new Promise(async (resolve, reject) => {
        try {
            await deleteDoc(doc(db, payload.collection, payload.doc));
            resolve()
        } catch (e) {
            reject(e)
        }
    })
}

// Delete Docs
export const deleteFile = function (payload) {
    return new Promise(async (resolve, reject) => {
        try {
            await deleteDoc(doc(db, "customers", payload.customerId, "files", payload.fileId));
            resolve()
        } catch (e) {
            reject(e)
        }
    })
}

// Custom Functions
// For getting more complex docs with multiple where clauses
export const GetReminderCalls = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db, "jobs")
            // , where("status", "!=", "COMPLETE")
            , where("callFrequency", "==", "daily")
            , where("active", "==", true)
            );
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getFiles = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db, "customers", payload.customerId, "files"));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}


export const getProjectByCustomerId = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db, "projects")
            // , where("status", "!=", "COMPLETE")
            , where("customerId", "==", payload.customerId)
            );
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}


export const GetRemindersByFriday = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db, "jobs")
            // , where("status", "!=", "COMPLETE")
            , where("callFrequency", "==", "friday")
            , where("active", "==", true)
            );
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const GetRemindersByDay = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
const startOfToday = payload.start 
const endOfToday = payload.end

            const q = query(collection(db, "reminders")
            // , where("status", "!=", "COMPLETE")
            , where("reminderDate", ">=", startOfToday)
            , where("reminderDate", "<=", endOfToday)
            , orderBy("reminderDate", 'asc')
            );
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}


export const getLatestJob = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{

            const q = query(collection(db, "jobs")
            , orderBy("projectId", 'desc')
            , limit(1)
            );
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr[0])
        } catch(e){
            reject(e)
        }
    })
}

export const getDocsByUser = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db, payload.collection), where("userId", "==", payload.userId));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getJobsByProjectId = function (payload) {
    return new Promise(async (resolve, reject) => {
        try {
            // Only parseInt if payload is a string
            const t = typeof payload === "string" ? parseInt(payload, 10) : payload;

            const q = query(collection(db, "jobs"), where("projectId", "==", t));
            const querySnapshot = await getDocs(q);

            let arr = [];
            querySnapshot.forEach((doc) => {
                arr.push({ ...doc.data(), id: doc.id });
            });
            resolve(arr);
        } catch (e) {
            reject(e);
        }
    });
};


export const getJobsByCustomerId = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db, "jobs"), where("customerId", "==", payload));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getLeads = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"leads"));
            const querySnapshot = await getDocs(q);

            let arr = []
            for (let i = 0; i < querySnapshot.docs.length; i++) {
                const doc = querySnapshot.docs[i];
                arr.push({ ...doc.data(), id: doc.id });
              }

            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}
export const getCrmReminders = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"crmReminders"));
            const querySnapshot = await getDocs(q);

            let arr = []
            for (let i = 0; i < querySnapshot.docs.length; i++) {
                const doc = querySnapshot.docs[i];
                arr.push({ ...doc.data(), id: doc.id });
              }

            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getCustomers = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"customers"), orderBy("company", "asc"));
            const querySnapshot = await getDocs(q);

            let arr = []
            for (let i = 0; i < querySnapshot.docs.length; i++) {
                const doc = querySnapshot.docs[i];
                arr.push({ ...doc.data(), id: doc.id });
              }

            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getClients = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"clients"), orderBy("name", "asc"));
            const querySnapshot = await getDocs(q);

            let arr = []
            for (let i = 0; i < querySnapshot.docs.length; i++) {
                const doc = querySnapshot.docs[i];
                arr.push(doc.data().name);
              }

            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getJobs = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"jobs"));
            const querySnapshot = await getDocs(q);

            let arr = []
            for (let i = 0; i < querySnapshot.docs.length; i++) {
                const doc = querySnapshot.docs[i];
                arr.push({...doc.data(), id: doc.id});
              }

            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getUsers = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"users"), orderBy("name", "asc"));
            const querySnapshot = await getDocs(q);

            let arr = []
            for (let i = 0; i < querySnapshot.docs.length; i++) {
                const doc = querySnapshot.docs[i];
                arr.push({ ...doc.data(), id: doc.id });
              }

            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getProjects = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"jobs"), where("company", "==", payload.companyName), where("active", "==", true));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}


export const getProjectsByClient = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"projects"), where("customerId", "==", payload.customerId), where("active", "==", true));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}


export const getReminders = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db,"reminders"), where("projectId", "==", payload.projectId), orderBy('created'));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}
export const getContractors = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            // Just get contractors
            // const q = query(collection(db,"users"), where("role", "==", "contractor"), orderBy("name", "asc"));
            // get all team used to allocate jobs to testers and admin
            const q = query(collection(db,"users"), orderBy("name", "asc"));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}
export const getDocsOrderByCompany = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db, payload.collection), orderBy("company", "asc"));
            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

export const getJobsByDate = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{

            let q 
            if(payload.tester){
                // Show result only where contactor is equal to payload.tester
                // also testers can only see published jobs
                q = query(collection(db, payload.collection), 
            where('date', '>', payload.dateFrom),
            where('date', '<', payload.dateTo),
            where('contractor.id', '==', payload.tester),
            where('published', '==', true),
            orderBy("date"));
            } else {
                q = query(collection(db, payload.collection), 
            where('date', '>', payload.dateFrom),
            where('date', '<', payload.dateTo),
            orderBy("date"));
            }
            

            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}
export const getJobsByDateAndContractor = function (payload) {
    return new Promise(async (resolve, reject) => {
        try{
            const q = query(collection(db, payload.collection), 
            where('date', '>', payload.dateFrom),
            where('date', '<', payload.dateTo),
            where('contractor.id', '==', payload.contractorId),
            orderBy("date"));

            const querySnapshot = await getDocs(q);

            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({...doc.data(), id: doc.id})
            });
            resolve(arr)
        } catch(e){
            reject(e)
        }
    })
}

// Authentication
//Reset
export const resetPassword = function (payload) {
    return new Promise(async (resolve, reject) => {
        sendPasswordResetEmail(auth, payload.email)
            .then(() => {
                // Signed in 
                resolve()
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const signUp = function (payload) {
    return new Promise(async (resolve, reject) => {
        createUserWithEmailAndPassword(auth, payload.email, payload.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                resolve(user)
            })
            .catch((error) => {
                reject(error)
            });
    })
}

// SignIn
export const signIn = function (payload) {
    return new Promise(async (resolve, reject) => {
        signInWithEmailAndPassword(auth, payload.email, payload.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                resolve(user)
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const signMeOut = function (payload) {
    return new Promise(async (resolve, reject) => {
        signOut(auth).then(() => {
            console.log('User signed out!');
            resolve()
          }).catch((error) => {
            console.error('Error signing out:', error);
            reject()
          });
    })
}

export const listenToState = function () {
    return new Promise(async (resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in
                const uid = user.uid;
                resolve(uid)
            } else {
                console.log("UID", user)
                reject()
            }
        });
    })
}

// Storage

export const upload = function (payload){
    return new Promise(async (resolve, reject) => {
        const storageRef = ref(storage, payload.child);

        uploadString(storageRef, payload.base, 'base64').then((snapshot) => {
            resolve(snapshot)
        }).catch(e => {
            reject(e)
        })

    })
}

