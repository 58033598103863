import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ClientBook from '../views/ClientBook.vue'
import ClientDocs from '../views/ClientDocs.vue'
import CallLog from '../views/CallLog.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import Onboarding from '../views/Onboarding.vue'
import Invite from '../views/Invite.vue'
import Jobs from '../views/Jobs.vue'
import ClientPortal from '../views/ClientPortal.vue'
import Users from '../views/Users.vue'
import Dashboard from '../views/Dashboard.vue'
import Test from '../views/Test.vue'
import Crm from '../views/Crm.vue'
import AcceptJobs from '../views/accept_jobs.vue'
import Outlook from '../views/Outlook.vue'
import Azure from '../views/azure.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/client-portal/:id',
    name: 'ClientPortal',
    component: ClientPortal
  },
  {
    path: '/callbacks/azure',
    name: 'Azure',
    component: Azure
  },

  {
    path: '/outlook',
    name: 'Outlook',
    component: Outlook
  },
  {
    path: '/accept-jobs',
    name: 'AcceptJobs',
    component: AcceptJobs
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/crm',
    name: 'Crm',
    component: Crm
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/client-book',
    name: 'ClientBook',
    component: ClientBook
  },
  {
    path: '/client-docs',
    name: 'ClientDocs',
    component: ClientDocs
  },
  {
    path: '/call-log',
    name: 'CallLog',
    component: CallLog
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding
  },
  {
    path: '/invite/:id',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
