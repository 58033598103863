<template>
    <div class="w-full">
        <!-- Modal for OneDrive Files -->
        <div v-if="openFiles" class="w-full h-screen fixed overflow-hidden bg-gray-900 bg-opacity-50 flex z-50 items-center justify-center">
            <div class="w-96 h-2/3 bg-white rounded-lg shadow-lg p-6 relative">
                <div class="absolute top-0 right-0 m-2">
                    <div @click="closeFilesModal" class="cursor-pointer hover:bg-primary hover:text-white h-6 w-6 bg-gray-100 rounded flex items-center justify-center">x</div>
                </div>
                <h2 class="header">One Drive Files</h2>
                <div v-if="loading" class="text-center"><span>Loading files...</span></div>
                <div v-else>
                    <div class="bg-gray-100 p-4 rounded-lg h-60 overflow-y-scroll">
                        <button v-if="folderStack.length" @click="goBack" class="text-sm text-primary underline mb-2">Back</button>
                        <ul>
                            <li v-for="file in files" :key="file.id" class="flex items-center mb-2">
                                <template v-if="file.metadata.folder">
                                    <span @click="toggleFolder(file)" class="cursor-pointer flex items-start">
                                        <img src="https://static-00.iconduck.com/assets.00/folder-icon-256x204-0171zqe6.png" alt="Folder" class="w-5 h-5 mr-2 text-sm"> {{ file.name }} 
                                    </span>
                                </template>
                                <template v-else>
                                    <div @click="selectFile(file)" :class="['flex items-start cursor-pointer p-2 rounded', selectedFiles.includes(file.id) ? 'bg-green-100 text-primary' : '']">
                                        <img src="https://cdn-icons-png.flaticon.com/512/124/124837.png" alt="File" class="w-5 h-5 mr-2 text-sm"> {{ file.name }} 
                                        <span v-if="selectedFiles.includes(file.id)" class="ml-auto text-green-600">✔</span>
                                    </div>
                                </template>
                            </li>
                        </ul>
                    </div>
                    <button v-if="selectedFiles.length" @click="attachFiles" class="mt-4 text-sm w-full bg-green-600 text-white py-2 rounded">Attach Selected Files</button>
                </div>
            </div>
        </div>

        <!-- File List -->
        <div class="container mx-auto" v-if="customerId && customer">
            <div class="w-full flex items-center justify-end">
                <outlook class="mr-2" />
                <div v-if="user && user.outlookToken">
                    <div @click="openFilesModal" class="w-56 text-sm hover:bg-secondary cursor-pointer bg-primary h-12 px-4 flex items-center justify-center rounded text-white">Attach OneDrive File</div>
                </div>
            </div>
            {{ customer.company }}
            <div v-if="attachedFiles.length" class="mt-4">
                <h2>Attached Files:</h2>
                <ul>
                    <li v-for="file in attachedFiles" :key="file.id" class="flex items-center mb-2">
                        <span>{{ file.name }}</span>
                        <button @click="downloadFile(file.id)" class="ml-4 bg-blue-600 text-white px-2 py-1 rounded">Download</button>
                        <button @click="openSendModal(file)" class="ml-2 bg-green-600 text-white px-2 py-1 rounded">Send</button>
                        <button @click="deleteFile(file.id)" class="ml-2 bg-red-600 text-white px-2 py-1 rounded">Delete</button>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Send File Modal -->
        <div v-if="sendModal.file" class="w-full h-screen fixed overflow-hidden bg-gray-900 bg-opacity-50 flex z-50 items-center justify-center">
            <div class="w-96 bg-white rounded-lg shadow-lg p-6">
                <h2>Send File</h2>
                <textarea v-model="sendModal.message" placeholder="Enter your message here" class="w-full border p-2 rounded mb-4"></textarea>
                <div class="text-gray-700 mb-4">{{ sendModal.file.name }}</div>
                <button @click="sendFile" class="w-full bg-blue-600 text-white py-2 rounded">Send</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { getFire, setFiles, getFiles, deleteFile } from "../firestore/utils";
import { auth } from "../firebase";
import Outlook from "@/views/Outlook.vue";

export default {
    data() {
        return {
            openFiles: false,
            customerId: null,
            customer: null,
            user: null,
            files: [],
            selectedFiles: [],
            folderStack: [],
            loading: false,
            attachedFiles: [],
            sendModal: { file: null, message: "" },
        };
    },
    components: { Outlook },
    methods: {
        async getCustomer() {
            this.customer = await getFire({ collection: "customers", doc: this.customerId });
        },
        async getCurrentUser() {
            return new Promise((resolve, reject) => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    unsubscribe();
                    user ? resolve(user) : reject(new Error("No user is signed in."));
                });
            });
        },
        async getUser() {
            const _user = await this.getCurrentUser();
            this.user = await getFire({ collection: "users", doc: _user.uid });
        },
        async openFilesModal() {
            this.openFiles = true;
            this.selectedFiles = []; // Clear selected files
            this.loading = true;
            try {
                const response = await axios.post("https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/onedrive/get-docs", {
                    accessToken: this.user.outlookToken,
                });
                this.files = response.data.files.map((file) => ({ ...file, open: false, children: [] }));
            } catch (error) {
                console.error("Error fetching OneDrive files:", error);
            } finally {
                this.loading = false;
            }
        },
        closeFilesModal() {
            this.openFiles = false;
        },
        async toggleFolder(folder) {
            if (folder.open) {
                folder.open = false;
                return;
            }
            folder.open = true;
            this.folderStack.push(this.files);
            this.loading = true;
            try {
                const response = await axios.post("https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/onedrive/get-folder-contents", {
                    accessToken: this.user.outlookToken,
                    folderId: folder.id,
                });
                folder.children = response.data.files.map((file) => ({ ...file, open: false, children: [] }));
                this.files = folder.children;
            } catch (error) {
                console.error("Error fetching folder contents:", error);
            } finally {
                this.loading = false;
            }
        },
        goBack() {
            if (this.folderStack.length > 0) {
                this.files = this.folderStack.pop();
            }
        },
        selectFile(file) {
            if (this.selectedFiles.includes(file.id)) {
                this.selectedFiles = this.selectedFiles.filter((id) => id !== file.id);
            } else {
                this.selectedFiles.push(file.id);
            }
        },
        async attachFiles() {
            try {
                for (const fileId of this.selectedFiles) {
                    const file = this.files.find((f) => f.id === fileId);
                    await setFiles({
                        customerId: this.$route.query.customerId,
                        body: file
                    });
                }
                this.openFiles = false;
                this.listenForFiles();
            } catch (error) {
                console.error("Error attaching files:", error);
            }
        },
        async listenForFiles() {
            this.loading = true
           this.attachedFiles = await getFiles({customerId: this.$route.query.customerId})
           this.loading = false
        },
        async downloadFile(fileId) {
            try {
                const response = await axios.post("https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/onedrive/download-doc", {
                    accessToken: this.user.outlookToken,
                    fileId,
                });
                window.open(response.data.url, "_blank");
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        },
        openSendModal(file) {
            this.sendModal.file = file;
            this.sendModal.message = "";
        },
        async sendFile() {
            try {
                await axios.post("/send-email", {
                    email: "recipient@example.com", // Replace with recipient's email
                    fileName: this.sendModal.file.name,
                    message: this.sendModal.message,
                });
                this.sendModal.file = null;
                alert("File successfully sent!");
            } catch (error) {
                console.error("Error sending file:", error);
            }
        },
        async deleteFile(fileId) {
         
            try {
                await deleteFile({
                    customerId: this.$route.query.customerId,
                    fileId: fileId
                });
                this.listenForFiles()
            } catch (error) {
                console.error("Error deleting file:", error);
            }
        },
    },
    async created() {
        this.customerId = this.$route.query.customerId;
        await this.getCustomer();
        await this.getUser();
        this.listenForFiles();
    },
};
</script>
