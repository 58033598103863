<template>
  <div
    class="fixed top-0 left-0 z-50 w-full flex items-center justify-center h-screen bg-gray-900 bg-opacity-25"
  >
    <div
      class="w-2/3 h-2/3 overflow-y-auto relative bg-white rounded-lg shadow-lg px-10 pt-6"
    >
      <div
        @click="toggleView()"
        class="m-4 absolute top-0 right-0 cursor-pointer hover:text-gray-900 text-secondary"
      >
        <i class="fa-solid fa-xmark"></i>
      </div>

      <div class="w-full flex flex-wrap">
        <div class="w-full text-left">
          <h2 class="font-bold text-xl">
            <!-- {{ client.company }} -->{{ customer.company }} 
            <router-link :to="{name: 'ClientDocs', query: {customerId: customer.id}}">  
            <div class="w-56 text-sm hover:bg-secondary cursor-pointer bg-primary h-14 px-4 flex items-center justify-center rounded text-white mt-2">
              Manage Documents
            </div>
          </router-link>
          </h2>
        </div>

        <div v-if="!showAddProject" class="w-full flex space-x-10 mt-10">
          <div class="w-full text-left">
            <div class="w-full flex">
              <h2 class="font-bold text-base text-gray-800">
                Projects ({{ projects.length }})
              </h2>
              <div class="flex-shrink ml-auto">
                <span
                  @click="showClosed = !showClosed"
                  :class="
                    showClosed
                      ? 'text-white bg-primary'
                      : 'bg-gray-100 text-gray-500'
                  "
                  class="px-3 py-2 rounded cursor-pointer text-sm"
                  >Show Closed Projects</span
                >
              </div>
              <div class="flex-shrink ml-2">
                <span
                  @click="showAddProject = true"
                  class="bg-primary text-white px-3 py-2 rounded cursor-pointer text-sm"
                  >Add Project</span
                >
              </div>
            </div>

            <div class="w-full flex flex-wrap mt-4 mb-10">
              <div
                class="w-full"
                v-for="(p, index) in projects"
                :key="'project' + index"
              >
                <project-tile :project="p" v-if="p.active || showClosed" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="showAddProject" class="w-full flex space-x-10 mt-4">
          <div class="w-full text-left">
            <div class="w-full flex">
              <h2 class="font-bold text-base text-gray-800">Create Project</h2>
              <div class="flex-shrink ml-auto">
                <span
                  @click="showAddProject = false"
                  class="bg-gray-100 text-gray-500 px-3 py-2 rounded cursor-pointer text-sm"
                  >Go Back</span
                >
              </div>
            </div>

            <div class="w-full flex flex-wrap mt-4 mb-10">
              <div class="grid grid-cols-12 w-full gap-2 bg-gray-100 rounded-lg p-6">
                <div
                  class="col-span-6 flex items-center justify-start"
                >
                  <div
                    class="hidden sm:block flex-shrink mr-3 text-left text-sm mb-1"
                  >
                    <div class="w-20 text-right">Client</div>
                  </div>
                  <select
                    type="text"
                    v-model="client"
                    class="rounded w-full h-10 bg-white outline-none px-2 text-gray-600"
                  >
                    <option
                      :value="c"
                      v-for="(c, index) in clients"
                      :key="index"
                    >
                      {{ c }}
                    </option>
                  </select>
                </div>
                
                <div
                  class="col-span-6 flex items-center justify-start"
                >
                <div
                class="sm:block hidden flex-shrink whitespace-nowrap mx-3 text-left text-sm mb-1"
              >
                Call Frequency
              </div>
              <select
                v-model="callFrequency"
                class="rounded w-20 h-8 bg-white outline-none px-2 text-gray-600"
              >
                <option value="daily">Daily</option>
                <option value="never">Don't Call</option>
              </select>
                </div>

                <div
              class="col-span-6 col-start-1 h-full items-center justify-end flex"
            >
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">
                  Project ID
                </div>
              </div>
              <input
                :disabled="true"
                type="number"
                v-model="projectId"
                :placeholder="latestJob.projectId + 1"
                class="rounded h-10 bg-gray-200 w-full outline-none px-2 text-gray-500"
              />
              
            </div>

            <div
              class="col-span-6 h-full items-center justify-end flex"
            >
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">Project Name</div>
              </div>
              <input
                type="text"
                placeholder="The Pavillion"
                v-model="projectName"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />


            </div>

            <div class="col-span-12 sm:col-span-6 h-12 items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">PO Number</div>
              </div>
              <input
                type="text"
                placeholder="QC X3"
                v-model="poNumber"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />
            </div>
            <div class="col-span-12 sm:col-span-4 sm:col-start-1 h-12 items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">Contact</div>
              </div>
              <input
                type="text"
                placeholder="Frank Junior"
                v-model="contact"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />
            </div>
            <div class="col-span-12 sm:col-span-4 h-12 items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-14 text-right">Phone</div>
              </div>
              <input
                type="text"
                placeholder="+61431000000"
                v-model="phone"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />
            </div>
            <div class="col-span-12 sm:col-span-4 h-12 items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-14 text-right">Email</div>
              </div>
              <input
                type="text"
                placeholder="frank@test.com"
                v-model="email"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />
            </div>
            <div
              class="sm:col-start-1 col-span-12 sm:col-span-6 h-10 items-center flex"
            >
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">Address</div>
              </div>
              <vue-google-autocomplete
                :options="autocompleteOptions"
                id="map"
                v-model="address"
                country="au"
                ref="autocomplete"
                v-on:inputChange="onInput"
                classname="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
                placeholder="Project Address"
                @placechanged="getAddressData"
              >
              </vue-google-autocomplete>
              
              
            </div>

            <div class="col-span-12 flex items-center justify-start">
              <div
            @click="submitForm(true)"
            class="bg-primary ml-24 cursor-pointer hover:bg-secondary text-white rounded m-2 p-4 header-heavy"
          >
            Create Project Job
          </div>
            </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setGeoPoint } from "../../firebase";
const geofire = require("geofire-common");
import { getJobsByCustomerId, getClients, getLatestJob, postFire } from "../../firestore/utils";
import { bus } from "../../main";
import ProjectTile from "@/components/cards/ProjectTile";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  props: ["customer"],
  data() {
    return {
      address: '',
      email: '',
      phone: '',
      contact: '',
      poNumber: '',
      latestJob: null,
      client: '',
      projectId: '',
      projectName: '',
      callFrequency: 'Daily',
      clients:[],
      projects: [],
      showClosed: false,
      showAddProject: false,
      autocompleteOptions: {
        types: ["geocode"], // Set the desired place types
      },
      addressFound: false,
      overrideAddress: "",
      geoHash: null,
      geoHashPoint: null,
      addressObj: null,
    };
  },
  methods: {
    async initiate() {
      const allJobs = await getJobsByCustomerId(this.customer.id);
      this.clients = await getClients();
      this.getTheLatestJob()
      console.log("allJobs", allJobs);
      this.mapJobsToProject(allJobs);
    },
    // Address Formatting
    getAddressData(addressData, placeResultData, id) {
      if (addressData && addressData.route && addressData.street_number) {
      this.address = addressData;
      this.addressFound = true
      console.log("addressData if statment", addressData)
      }
      
    },
    onInput(a) {
      // this.autoSave()
      // this.overrideAddress = a.newVal
      // this.address = this.overrideAddress
      this.overrideAddress = a.newVal
    },
    async getTheLatestJob(){
      this.latestJob = await getLatestJob()
      if(!this.latestJob) this.latestJob = {projectId: 0}
      this.projectId = this.latestJob.projectId + 1
      console.log("latestJob", this.latestJob)
    },
    toggleView() {
      bus.$emit("closeClientCard");
    },
    mapJobsToProject(jobs) {
      jobs.map((i) => {
        const exists = this.projects.filter((x) => x.projectId == i.projectId);
        console.log("exists", exists);
        if (exists.length > 0)
          this.projects[this.projects.indexOf(exists[0])].jobs.push(i);
        else {
          this.projects.push({
            id: i.id,
            projectId: i.projectId,
            projectName: i.projectName,
            projectAddress: i.formattedAddress,
            contact: i.contact,
            phone: i.phone,
            email: i.email,
            active: i.active,
            jobs: [i],
          });
        }
      });
    },
    async submitForm(_redirect) {
      
      if(this.projectId <= this.latestJob.projectId) alert(`Project Id must be larger than the lastest project which is ${this.latestJob.projectId}`)
      if (
        this.projectId &&
        this.projectName &&
        this.contact &&
        this.phone &&
        this.poNumber &&
        this.email
      ) {
        try {
          
          this.loading = true;
          // GEO Hash
          let geohash = this.geoHash;
          let geopoint = this.geoPoint;
          let _address = this.addressObj
          let _formattedAddress = ''
          
          // if(_address && _address.route) _formattedAddress = this.formatAddress(_address)

          if(!this.addressFound){
            _formattedAddress = this.overrideAddress
            _address = null
            geohash = ''
            geopoint = null
          }
          
          
          console.log("this address", this.address)
          console.log("this this.addressFound", this.addressFound)

          if (this.addressFound) {
            _formattedAddress = this.formatAddress(this.address)
            const lat = this.address.latitude;
            const lng = this.address.longitude;
            _address = this.address
            geohash = geofire.geohashForLocation([lat, lng]);
            // GEO point
            geopoint = setGeoPoint({
              latitude: this.address.latitude,
              longitude: this.address.longitude,
            });
          }

          console.log("geopoint", geopoint)
          console.log("geohash", geohash)
          console.log("geohash", geohash)
          console.log("_formattedAddress", _formattedAddress)


          // Loop Jobs and save
          

            let payload = {
              // Client
              created: new Date(),
              formattedAddress: _formattedAddress,
              client: this.client,
              active: true,
              // Customer
              company: this.customer.company, // CHECK
              phone: this.phone,
              email: this.email,
              callFrequency: this.callFrequency,
              contact: this.contact,
              poNumber: this.poNumber,
              customerId: this.customer.id, // CHECK
              // Project Specific
              address: _address,
              projectId: this.projectId,
              projectName: this.projectName,
              geohash,
              geopoint,
              // Job specific
              status: '',
              contractor: null,
              meters: null,
              mixCode: '',
              published: false,
              requirements: '',
              notes: '',
              updated: new Date()
            };


            const a = await postFire({
                collection: "jobs",
                body: payload,
              });

           

          this.projectName = "";
          this.projectId = this.latestJob.projectId + 1;
          this.status = "";
          this.callFrequency = "";
          this.contact = "";
          this.poNumber = "";
          this.phone = "";
          this.email = "";
          this.search = "";
          this.projects = [];
          this.address = "";
          this.$refs.autocomplete.clear();
          this.editMode = null;
          
        this.showAddProject = false
     

        } catch (e) {
          alert("Something went wrong");
          console.log("Error", e);
        }
      } else {
        alert("Required fields - Project Id, ProjectName, Contact, Phone, Email");
        console.log("projectId", this.projectId);
      console.log("projectName", this.projectName);
      console.log("contact", this.contact);
      console.log("poNumber", this.poNumber);
      console.log("phone", this.phone);
      console.log("email", this.email);
      console.log("address", this.address);

        // alert("All fields must be entered before adding a job");
      }
      this.$router.push({ name: 'Jobs', query: { jobId: a.id } })
      
      this.toggleView()
    },
    formatAddress(address) {
      if (this.addressFound) {
        if (address && address.route) {
          return `${address.street_number} ${address.route} ${address.locality}, ${address.administrative_area_level_1} ${address.country} ${address.postal_code}`;
        } else {
          return null;
        }
      }
    },
  },
  created() {
    this.initiate();
  },
  components: {
    ProjectTile,
    VueGoogleAutocomplete
  },
};
</script>