<template>
  <div class="flex flex-col bg-white w-full">

    <!-- <div class="w-full h-12 bg-black text-white my-10" @click="testSaveProjects()">save test jobs</div> -->
    <div
      v-if="showNewClient"
      class="w-full h-full bg-gray-900 bg-opacity-20 flex items-center justify-center fixed z-50"
    >
      <div class="w-96 bg-white shadow-lg p-14 rounded-xl relative">
        <i
          @click="(showNewClient = false), (client = clients[0])"
          class="fa-solid fa-xmark cursor-pointer hover:text-red-700 absolute top-0 right-0 m-4"
        ></i>

        <h2 class="header-bold text-lg">Add New Client</h2>
        <input
          type="text"
          v-model="newClient"
          placeholder="Name of client"
          class="rounded h-10 my-2 bg-white w-full border border-gray-200 outline-none px-2 text-gray-600"
        />

        <div
          @click="saveClient()"
          class="bg-primary cursor-pointer hover:bg-secondary text-white rounded p-4 header-heavy"
        >
          Save Client
        </div>
      </div>
    </div>

    <nav-menu />
    <div class="h-auto w-full bg-gray-900 relative">
      <div
        v-if="setReminder"
        class="fixed z-10 inset-0 bg-gray-900 bg-opacity-25 overflow-y-auto"
      >
        <div class="flex items-center justify-center min-h-screen px-4">
          <div
            class="bg-white overflow-y-scroll relative rounded-lg p-6 shadow-lg w-96 h-96"
          >
            <i
              @click="setReminder = false"
              class="fa-solid fa-xmark cursor-pointer hover:text-red-700 absolute top-0 right-0 m-4"
            ></i>

            <h2 class="text-lg font-semibold mb-4">Reminder Calls</h2>

            <flat-pickr
              class="text-xs w-full bg-gray-100 h-12 rounded px-3 mb-2"
              :config="config"
              v-model="reminderDate"
            ></flat-pickr>

            <textarea
              name="reminderText"
              v-model="reminderText"
              id="reminderText"
              placeholder="reminder information"
              class="rounded bg-gray-100 p-2 text-xs text-gray-600 w-full h-12"
            ></textarea>
            <div
              @click="saveReminder()"
              class="rounded w-full h-12 flex items-center justify-center bg-primary text-white cursor-pointer hover:bg-secondary"
            >
              Set Reminder
            </div>
            <table class="w-full mt-4 text-xs">
              <thead>
                <tr class="bg-gray-200 h-10 text-gray-500 rounded-t py-2">
                  <th class="font-normal text-sm">Reminders</th>
                  <th class="font-normal text-sm"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="border-b border-gray-100 py-2 h-10"
                  v-for="(reminder, index) in reminders"
                  :key="index"
                >
                  <td class="text-left">
                    <div class="w-full">
                      {{
                        $moment(reminder.reminderDate.seconds * 1000).format(
                          "DD MMM YYYY - HH:mmA"
                        )
                      }}
                      <div class="text-xs w-full">
                        {{ reminder.reminderText }}
                      </div>
                    </div>
                  </td>

                  <td>
                    <button
                      @click="deleteReminder(reminder.id)"
                      class="text-red-500"
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="container mx-auto text-left flex items-center">
        <div class="flex-grow sm:ml-0 ml-6">
          <h2
            v-if="!editMode"
            class="text-white header-heavy tracking-tight text-base sm:text-xl mt-2"
          >
            Add New Job
          </h2>
          <h2
            v-else
            class="text-white header-heavy tracking-tight text-base sm:text-xl mt-2"
          >
            Edit Job
          </h2>
        </div>
        <div class="flex-shrink flex space-x-2">
          <div
            v-if="newProject"
            @click="submitForm(false)"
            class="bg-primary cursor-pointer hover:bg-secondary text-white rounded m-2 p-4 header-heavy"
          >
            Create Job
          </div>
          <div
            v-if="!newProject"
            @click="setReminder = true"
            class="bg-yellow-500 flex items-center cursor-pointer hover:bg-yellow-600 text-white rounded m-2 p-4 header-heavy"
          >
            <i class="hidden sm:block fa-solid fa-user-clock"></i>
            <span class="sm:block hidden mx-1">Set </span> Reminder
          </div>
          <div
            v-if="!newProject"
            @click="submitForm(true)"
            class="sm:mr-0 mr-4 flex bg-primary cursor-pointer hover:bg-secondary text-white rounded my-2 p-4 header-heavy"
          >
            Save <span class="sm:block mx-1 hidden">Job</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="h-auto w-full overflow-y-scroll relative rounded-t-xl bg-gray-200"
    >
      <transition name="slide">
        <div class="fixed top-0 right-0 z-50 sm:w-96" v-if="createCustomer">
          <create-customer :customer="customer" />
        </div>
      </transition>
      <div class="container mx-auto p-2">
        <div class="grid grid-cols-12 sm:gap-20">
          <div
            class="col-span-12 sm:col-span-3 flex flex-wrap items-center justify-start"
          >
            <div
              class="hidden sm:block flex-shrink mr-3 text-left text-sm mb-1"
            ></div>
            <select
              type="text"
              v-model="client"
              @change="openNewClient()"
              class="rounded flex-grow h-10 bg-white outline-none px-2 text-gray-600"
            >
              <option :value="c" v-for="(c, index) in clients" :key="index">
                {{ c }}
              </option>
              <option value="new">Add New Client</option>
            </select>
          </div>
          <div class="col-span-12 sm:col-span-8 h-full flex">
            <div class="w-2/3 text-left flex items-center">
              <div
                class="hidden sm:block flex-shrink mr-3 text-left text-sm mb-1"
              >
                <div class="w-14">Customer</div>
              </div>
              <div class="flex-grow relative">
                <multiselect
                  label="company"
                  v-if="!customer"
                  track-by="company"
                  @search-change="searchCustomers"
                  :loading="loadingSelect"
                  v-model="customer"
                  :options="customers"
                  :optionsLimit="300000"
                >
                </multiselect>

                <div
                  class="flex-shrink flex bg-gray-100 rounded px-2 py-2 text-sm w-96"
                  v-else
                >
                  <div class="flex w-full h-full items-center">
                    <span class="font-bold text-sm mr-2">{{
                      customer.company
                    }}</span>
                    -
                    <span class="ml-2 text-xs text-gray-400">{{
                      customer.contact
                    }}</span>
                    <!-- <div class="w-full flex text-xs">
                        <div class="flex-shrink font-bold">Contact Name: </div>
                        <div class="flex-shrink ml-2">{{ customer.contact }} </div>
                      </div>
                      <div class="w-full flex text-xs">
                        <div class="flex-shrink text-sm font-bold">Contact Phone: </div>
                        <div class="flex-shrink ml-2"> <a :href="'tel:' + customer.phone" class="text-primary cursor-pointer">{{ customer.phone }}</a> </div>
                      </div>
                      <div class="w-full flex text-xs">
                        <div class="flex-shrink font-bold">Contact Email: </div>
                        <div class="flex-shrink ml-2"> <a :href="'mailto:' + customer.email" class="text-primary cursor-pointer">{{ customer.email }}</a> </div>
                      </div> -->

                    <div class="flex-shrink ml-auto">
                      <span
                        @click="createCustomer = !createCustomer"
                        class="text-xs text-white rounded-full py-1 px-3 mx-2 bg-primary cursor-pointer hover:bg-secondary cursor-pointer"
                      >
                        Edit
                      </span>
                    </div>
                  </div>

                  <div
                    class="hidden sm:flex flex-shrink pt-1"
                    v-if="customer"
                    @click="customer = null"
                  >
                    <i
                      class="text-red-500 cursor-pointer fa-solid fa-xmark"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!customer" class="w-1/3 pl-4 flex items-center">
              <div
                @click="createCustomer = !createCustomer"
                class="rounded w-full bg-gray-900 cursor-pointer text-white h-12 px-4 items-center justify-center flex"
              >
                New
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full bg-gray-300" v-if="customer">
        <div class="container mx-auto p-2">
          <div class="grid grid-cols-12 gap-2">
            <div
              v-if="!newProject"
              class="col-span-12 sm:col-span-4 h-full items-center flex"
            >
              <div
                class="flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">Project</div>
              </div>
              <div class="w-full relative">
                <select
                  type="text"
                  v-model="projectId"
                  placeholder="Project Name"
                  class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
                >
                  <option
                    :value="p.projectId"
                    v-for="(p, index) in projects"
                    :key="'pId' + index"
                  >
                    #{{ p.projectId }} - {{ p.projectName }}
                  </option>
                </select>
              </div>
              <div class="flex-shrink">
                <div
                  @click="
                    showProject()
                  "
                  class="cursor-pointer hover:bg-secondary ml-4 h-10 w-10 bg-primary text-white rounded flex items-center justify-center"
                >
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
            </div>

            <div
              v-if="newProject"
              class="col-span-12 sm:col-span-3 h-full items-center justify-end flex"
            >
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">Project ID</div>
              </div>
              <input
                type="number"
                :disabled="true"
                v-model="projectId"
                :placeholder="latestJob.projectId + 1"
                class="rounded h-10 bg-gray-200 w-full outline-none px-2 text-gray-400"
              />
            </div>

            <div
              v-if="newProject"
              class="col-span-12 sm:col-span-4 h-full items-center justify-end flex"
            >
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">Project Name</div>
              </div>
              <input
                type="text"
                @keyup="autoSave()"
                placeholder="The Pavillion"
                v-model="projectName"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />

              <div class="flex-shrink">
                <div
                  @click="
                    newProject = false;
                    projectId = projects[0].projectId;
                  "
                  class="cursor-pointer hover:bg-secondary ml-4 h-10 w-10 bg-primary text-white rounded flex items-center justify-center"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
              </div>
            </div>

            <div
              class="pl-2 pr-1 rounded-lg col-span-12 sm:col-span-4 sm:col-start-9 h-full items-center justify-end flex"
            >
              <!-- <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                Active
              </div>
              <select
                v-model="active"
                @change="autoSave()"
                placeholder="active"
                class="rounded h-8 bg-white w-20 outline-none px-2 text-gray-600"
              >
                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select> -->
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mx-3 text-left text-sm mb-1"
              >
                Call Frequency
              </div>
              <select
                @change="autoSave()"
                v-model="callFrequency"
                class="rounded w-40 h-8 bg-white outline-none px-2 text-gray-600"
              >
                <option value="daily">Daily</option>
                <option value="never">Don't Call</option>
                <option value="friday">Fridays</option>
              </select>
            </div>

            <div class="col-span-12 sm:col-span-3 h-12 items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">PO Number</div>
              </div>
              <input
                type="text"
                @keyup="autoSave()"
                placeholder="PO"
                v-model="poNumber"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />
            </div>
            <div class="col-span-12 sm:col-span-3 h-12 items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">Contact</div>
              </div>
              <input
                type="text"
                @keyup="autoSave()"
                placeholder="Frank Junior"
                v-model="contact"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />
            </div>
            <div class="col-span-12 sm:col-span-3 h-12 items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-14 text-right">Phone</div>
              </div>
              <input
                type="text"
                @keyup="autoSave()"
                placeholder="+61431000000"
                v-model="phone"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />
            </div>
            <div class="col-span-12 sm:col-span-3 h-12 items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-14 text-right">Email</div>
              </div>
              <input
                type="text"
                @keyup="autoSave()"
                placeholder="frank@test.com"
                v-model="email"
                class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
              />
            </div>
            <div
              class="sm:col-start-1 col-span-12 sm:col-span-5 h-10 items-center flex"
            >
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                <div class="w-20 text-right">Address</div>
                <!-- {{ address }} -->
              </div>
              <vue-google-autocomplete
                :options="autocompleteOptions"
                id="map"
                v-model="address"
                country="au"
                ref="autocomplete"
                v-on:inputChange="onInput"
                classname="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
                placeholder="Project Address"
                v-on:placechanged="getAddressData"
              >
              </vue-google-autocomplete>
            </div>
            <div
              class="pl-4 col-span-12 sm:col-span-3 h-10 items-center flex"
            >
            <div class="flex whitespace-nowrap text-sm mr-4 ">Region</div>
            <input type="text" v-model="region" class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600">
            </div>
            <div
              class="pl-4 col-span-12 sm:col-span-3 h-10 items-center flex"
            >
            <div class="flex whitespace-nowrap text-sm mr-4 ">Project Type</div>
            <input type="text" v-model="projectType" class="rounded h-10 bg-white w-full outline-none px-2 text-gray-600">
            </div>
            <div
              class="col-start-1 col-span-12 sm:col-span-3 h-10 items-center flex"
            >

            <div class="flex whitespace-nowrap text-sm">Estimated Start</div>
            <flat-pickr
                    class=" cursor-pointer w-40 ml-4 rounded h-10 bg-white outline-none px-2 text-gray-600"
                    :config="configDate"
                    v-model="estimatedStartDate"
                  ></flat-pickr>
                
                </div>
            <div
              class=" col-span-12 sm:col-span-3 h-10 items-center flex"
            >
            <div class="flex whitespace-nowrap text-sm">Estimated End</div>
            <flat-pickr
                    class="cursor-pointer w-40 ml-4 rounded h-10 bg-white outline-none px-2 text-gray-600"
                    :config="configDate"
                    v-model="estimatedEndDate"
                  ></flat-pickr>
                
                </div>
          </div>
        </div>
      </div>

      <div class="w-full bg-white">
        <div class="container mx-auto p-4">
          <div class="grid grid-cols-4 gap-4">
            <div
              class="col-span-4 sm:col-span-1"
              :class="j.status == 'COMPLETED' || j.status == 'CANCELLED' ? 'hidden' : ''"
              v-for="(j, index) in jobs"
              :key="index"
            >
              <div
                :class="[
                  editMode == j.id ? 'pulse-glow' : '',
                  j.status == 'COMPLETED'
                    ? 'bggreenstripes bg-green-600'
                    : ' bg-gray-100',
                ]"
                class="w-full rounded p-4 shadow relative"
              >
                <div
                  class="absolute top-0 right-0 -m-1"
                  v-if="index >= 1 || j.id"
                  @click="removeJob(index)"
                >
                  <i
                    class="fa-solid fa-circle-minus text-red-600 hover:text-red-700 cursor-pointer text-lg"
                  ></i>
                </div>
                <div class="w-full relative mb-1">
                  <div class="absolute top-0 right-0 mr-4 mt-5">
                    <i class="fa-solid fa-calendar-days"></i>
                  </div>

                  <flat-pickr
                    class="h-14 cursor-pointer w-full text-xs text-gray-600 text-center bg-gray-200"
                    :config="config"
                    v-model="j.date"
                  ></flat-pickr>

                  <!-- <datetime
                    v-model="j.date"
                    type="datetime"
                    placeholder="Date and time"
                    format="DD-MM-YYYY HH:mm"
                    input-class=" w-full rounded bg-gray-200 pr-6 outline-none pl-6 h-12 text-gray-500 text-sm"
                  ></datetime> -->
                </div>

                <div
                  v-if="j.testerNotes"
                  class="my-6 text-left text-xs w-full bg-red-100 text-red-600 rounded-xl p-4"
                >
                  <strong>Tester Notes:</strong><br />
                  {{ j.testerNotes }}
                </div>
                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Mix Code
                  </div>
                  <input
                    type="text"
                    v-model="j.mixCode"
                    placeholder="ie: 123"
                    class="rounded h-12 text-right w-full bg-gray-50 outline-none px-6 text-gray-600"
                  />
                </div>
                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Meters
                  </div>
                  <input
                    type="text"
                    v-model="j.meters"
                    placeholder="ie: 4"
                    class="rounded h-12 text-right w-full bg-gray-50 outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Requirements
                  </div>
                  <input
                    type="text"
                    v-model="j.requirements"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Pour Location
                  </div>
                  <input
                    type="text"
                    v-model="j.pourLocation"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Notes for tester
                  </div>
                  <input
                    type="text"
                    v-model="j.notesForTester"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Notes
                  </div>
                  <input
                    type="text"
                    v-model="j.notes"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Tester
                  </div>
                  <select
                    type="text"
                    v-model="j.contractor"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  >
                    <option
                      v-for="(c, cIndex) in contractors"
                      :key="'con' + cIndex"
                      :value="c"
                    >
                      {{ c.name }}
                    </option>
                  </select>
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-white"
                  >
                    Status
                  </div>
                  <select
                    v-model="j.status"
                    :class="[
                      j.status == 'ON-CALL' ? 'bg-yellow-600 text-white' : '',
                      j.status == 'FIRM'
                        ? ' bggreenstripes bg-green-600 text-white'
                        : '',
                      j.status == 'COMPLETED' ? ' bg-green-600 text-white' : '',
                      j.status == 'CANCELLED' ? 'bg-red-700 text-white' : '',
                      j.status == 'COMMITTED' ? 'bg-blue-700 text-white' : '',
                    ]"
                    class="rounded h-12 w-full text-right text-sm bg-gray-50 outline-none px-2"
                  >
                    <option>ON-CALL</option>
                    <option>FIRM</option>
                    <option>COMMITTED</option>
                    <option>CANCELLED</option>
                    <option>COMPLETED</option>
                  </select>
                </div>

                <div class="w-full mt-2" @click="duplicateJob(j)">
                  <div
                    class="rounded bg-gray-500 text-white rounded justify-center flex items-center cursor-pointer hover:bg-gray-800 h-14"
                  >
                    Duplicate
                  </div>
                </div>
                <div class="w-full mt-2 relative">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-600"
                  >
                    Accept on behalf of tester
                  </div>

                  <div
                    
                  >
                  <select  @change="acceptJob(j)" v-model="j.accepted" class="text-gray-800 rounded h-12 w-full text-right text-sm bg-gray-50 outline-none px-2">
                    <option :value="true">Accepted</option>
                    <option :value="false">Not Accepted</option>
                  </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-span-4 sm:col-span-1 flex items-center"
              @click="addJob()"
            >
              <div
                class="w-full h-96 flex items-center rounded-lg hover:text-white justify-center bg-gray-100 border-2 border-dashed border-gray-400 cursor-pointer hover:border-gray-600 hover:bg-gray-500 rounded p-4 shadow"
              >
                <div class="w-full text-center">
                  <i class="fa-solid fa-plus text-4xl"></i>
                  <div>Add New Job</div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="border-t border-dashed border-gray-300 pt-10 mt-10 grid grid-cols-4 gap-4"
          >
            <div class="col-span-4 text-left">
              <h2 class="text-lg header-bold text-gray-700">Completed Jobs</h2>
            </div>
            <div
              class="col-span-4 sm:col-span-1"
              v-for="(j, index) in completeJobs"
              :key="index"
            >
              <div
                :class="[
                  editMode == j.id ? 'pulse-glow' : '',
                  j.status == 'COMPLETED'
                    ? 'bggreenstripes bg-green-600'
                    : ' bg-gray-100',
                ]"
                class="w-full rounded p-4 shadow relative"
              >
                <div
                  class="absolute top-0 right-0 -m-1"
                  v-if="index >= 1 || j.id"
                  @click="removeJob(index)"
                >
                  <i
                    class="fa-solid fa-circle-minus text-red-600 hover:text-red-700 cursor-pointer text-lg"
                  ></i>
                </div>
                <div class="w-full relative mb-1">
                  <div class="absolute top-0 right-0 mr-4 mt-5">
                    <i class="fa-solid fa-calendar-days"></i>
                  </div>

                  <flat-pickr
                    class="h-14 cursor-pointer w-full text-xs text-gray-600 text-center bg-gray-200"
                    :config="config"
                    v-model="j.date"
                  ></flat-pickr>
                </div>
                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Mix Code
                  </div>
                  <input
                    type="text"
                    v-model="j.mixCode"
                    placeholder="ie: 123"
                    class="rounded h-12 text-right w-full bg-gray-50 outline-none px-6 text-gray-600"
                  />
                </div>
                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Meters
                  </div>
                  <input
                    type="text"
                    v-model="j.meters"
                    placeholder="ie: 4"
                    class="rounded h-12 text-right w-full bg-gray-50 outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Requirements
                  </div>
                  <input
                    type="text"
                    v-model="j.requirements"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>
                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Pour Location
                  </div>
                  <input
                    type="text"
                    v-model="j.pourLocation"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>
                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Notes For Tester
                  </div>
                  <input
                    type="text"
                    v-model="j.notesForTester"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Notes
                  </div>
                  <input
                    type="text"
                    v-model="j.notes"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Tester
                  </div>
                  <select
                    type="text"
                    v-model="j.contractor"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  >
                    <option
                      v-for="(c, cIndex) in contractors"
                      :key="'con' + cIndex"
                      :value="c"
                    >
                      {{ c.name }}
                    </option>
                  </select>
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-white"
                  >
                    Status
                  </div>
                  <select
                    v-model="j.status"
                    :class="[
                      j.status == 'ON-CALL' ? 'bg-yellow-600 text-white' : '',
                      j.status == 'FIRM'
                        ? ' bggreenstripes bg-green-600 text-white'
                        : '',
                      j.status == 'COMPLETED' ? ' bg-green-600 text-white' : '',
                      j.status == 'CANCELLED' ? 'bg-red-700 text-white' : '',
                      j.status == 'COMMITTED' ? 'bg-blue-700 text-white' : '',
                    ]"
                    class="rounded h-12 w-full text-right text-sm bg-gray-50 outline-none px-2"
                  >
                    <option>ON-CALL</option>
                    <option>FIRM</option>
                    <option>COMMITTED</option>
                    <option>CANCELLED</option>
                    <option>COMPLETED</option>
                  </select>
                </div>

                <div class="w-full mt-2" @click="duplicateJob(j)">
                  <div
                    class="rounded bg-gray-500 text-white rounded justify-center flex items-center cursor-pointer hover:bg-gray-800 h-14"
                  >
                    Duplicate
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-span-4 sm:col-span-1 flex items-center"
              @click="addJob()"
            >
              <div
                class="w-full h-96 flex items-center rounded-lg hover:text-white justify-center bg-gray-100 border-2 border-dashed border-gray-400 cursor-pointer hover:border-gray-600 hover:bg-gray-500 rounded p-4 shadow"
              >
                <div class="w-full text-center">
                  <i class="fa-solid fa-plus text-4xl"></i>
                  <div>Add New Job</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import NavMenu from "@/components/headers/navMenu";
import CreateCustomer from "@/components/cards/createCustomer.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { bus } from "../main";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {
  putFire,
  postFire,
  getContractors,
  getJobsByProjectId,
  getFire,
  deleteFire,
  getReminders,
  getProjects,
  getLatestJob,
  getClients,
  setFire
} from "../firestore/utils";
import { getTimeStamp, setGeoPoint } from "../firebase";
const geofire = require("geofire-common");
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      config: {
        enableTime: true,
        altFormat: "d-m-Y H:i",
        altInput: true,
        allowInput: false,
      },
      configDate: {
        enableTime: false,
        altFormat: "d-m-Y",
        altInput: true,
        allowInput: false,
      },
      editedAddress: false,
      addressFound: false,
      showNewClient: false,
      newClient: "",
      completeJobs: [],
      completedJobs: [],
      latestJob: null,
      reminders: [],
      reminderDate: new Date(),
      reminderText: "",
      setReminder: false,
      overrideAddress: "",
      addressObj: null,
      newProject: false,
      // on created
      editJob: null,
      date: null,
      prevDate: null,
      job: null,
      // others
      loadingSelect: false,
      contractors: [],
      editMode: false,
      projects: [],
      autocompleteOptions: {
        types: ["geocode"], // Set the desired place types
      },
      active: true,
      customers: [],
      customer: null,
      callFrequency: "never",
      createCustomer: false,
      client: "",
      clients: [""],
      company: "",
      email: "",
      phone: "",
      contractor: null,
      customerId: "",
      address: "",
      region: "",
      projectType: "",
      estimatedStartDate: "",
      estimatedEndDate: "",
      projectId: "",
      projectName: "",
      contact: "",
      poNumber: "",
      jobs: [],
      geoHash: null,
      geoHashPoint: null,
      schema: {
        status: "ON-CALL",
        phone: "",
        email: "",
        contractor: null,
        date: "",
        meters: "",
        accepted: false,
        requirements: "",
        pourLocation: "",
        notesForTester: "",
        notes: "",
        mixCode: "",
        address: "",
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    projectId() {
      this.getReminders();
      if(!this.newProject) this.getProjectById(true);
    },
    jobs: {
      handler(newVal, oldVal) {
        // Perform any necessary actions
      },
      deep: true, // Enable deep watching
    },
    newProject() {
      if (this.newProject) {
        this.contact = "";
        this.poNumber = "";
        this.region = "";
        this.projectType = "";
        this.estimatedStartDate = "";
        this.estimatedEndDate = "";
        this.phone = "";
        this.email = "";
        this.jobs = [this.schema];
        this.address = "";
        this.geoHash = "";
        this.addressObj = null;
        this.geoPoint = null;
        this.editMode = null;
      } else {
        this.selectProject();
      }
    },
    customer() {
      if (!this.customer) {
        this.jobs = [this.schema];
      }
      this.newProject = false;
      
      this.getProjectById();
    },
  },
  methods: {
    showProject(){
      console.log("this.latestJob from show", this.latestJob)
      this.projectId = this.latestJob.projectId + 1;
      this.projectName = '';
      this.newProject = true;      
    },
    openNewClient() {
      if (this.client == "new") this.showNewClient = true;
    },
    async saveClient() {
      // Add to array
      this.clients.push(this.newClient);
      // Select as client
      this.client = this.newClient;

      await postFire({
        collection: "clients",
        body: {
          name: this.client,
          created: new Date(),
        },
      });

      this.showNewClient = false;
    },
    async getReminders() {
      this.reminders = await getReminders({ projectId: this.projectId });
    },
    async getClients() {
      this.clients = await getClients();
    },
    async saveReminder() {
      await postFire({
        collection: "reminders",
        body: {
          projectId: this.projectId,
          reminderDate: new Date(this.$moment(this.reminderDate)),
          reminderText: this.reminderText,
          created: new Date(),
        },
      });
      this.getReminders();
      // this.setReminder = false
    },
    async deleteReminder(reminder) {
      console.log("reminder", reminder);
      await deleteFire({
        collection: "reminders",
        doc: reminder,
      });
      this.getReminders();
    },
    autoSave() {
      // this.submitForm(false)
    },
    selectProject() {
      return new Promise((res, rej) => {
        // this.editMode = true;
        this.newProject = false;
        this.getJobs();
        // this.projects = [];
        res();
      });
    },

    async getJobs() {
      this.completedJobs = [];

      let jobs = await getJobsByProjectId(this.projectId);
      if (jobs.length > 0) {
        jobs = jobs.sort((a, b) => a.date - b.date);

        jobs.forEach((job) => {
          if (job.geohash) this.geoHash = job.geohash;
          if (job.geopoint) this.geoPoint = job.geopoint;
          this.projectName = job.projectName;
          this.phone = job.phone;
          this.region = job.region;
          this.projectType = job.projectType;
          this.client = job.client;
          this.email = job.email;
          this.addressObj = job.address;
          this.contact = job.contact;
          this.poNumber = job.poNumber;
          this.address = job.formattedAddress;

          // Date assignment
          if (job.estimatedEndDate && job.estimatedEndDate.seconds) {
            
            this.estimatedEndDate = new Date(job.estimatedEndDate.seconds * 1000).toISOString();
          } 
        
          // Date assignment
          if (job.estimatedStartDate && job.estimatedStartDate.seconds) {
            this.estimatedStartDate = new Date(job.estimatedStartDate.seconds * 1000).toISOString();
          }
        
          // Date assignment
          if (job.date && job.date.seconds) {
            job.date = new Date(job.date.seconds * 1000).toISOString();
          } else job.date = "";
        });
        // Get compoleted Jobs
        // this.completeJobs = jobs.filter((j) => j.status == "COMPLETED");
        this.completeJobs = jobs.filter((j) => j.status === "COMPLETED" || j.status === "CANCELLED");

        this.completeJobs = this.completeJobs.sort((a, b) => a.date - b.date);
        this.jobs = jobs.sort((a, b) => a.date - b.date);
        // Make active if one exists
        const _getJob = this.jobs.filter(
          (i) => i.id == this.$route.query.jobId
        );
        if (_getJob.length > 0) {
          const jobIndex = this.jobs.indexOf(_getJob[0]);
          if (jobIndex >= 0) {
            this.active = this.jobs[jobIndex].active;
          }
        }
      } else {
        // this.newProject = true
      }
    },

    formatAddress(address) {
      if (address && address.route) {
        const add = `${address.street_number || ""} ${address.route || ""} ${
          address.locality || ""
        }, ${address.administrative_area_level_1 || ""} ${
          address.country || ""
        } ${address.postal_code || ""}`;
        console.log("formatted address without", add);
        return add;
      } else {
        return this.overrideAddress;
      }
    },
    // Address Formatting
    getAddressData(addressData, placeResultData, id) {
      if (addressData && addressData.route && addressData.street_number) {
        this.address = addressData;
        this.addressFound = true;
        console.log("addressData if statment", addressData);
      } else {
        this.address = addressData;
        this.addressFound = false;
      }
    },
    onInput(a) {
      // this.autoSave()
      this.overrideAddress = a.newVal;
      this.editedAddress = true;
      // this.address = this.overrideAddress;
    },
    async acceptJob(job, status){
      // alert(job.ids)
      await setFire({
        collection: "jobs",
        doc: job.id,
        body: {
          accepted: true,
        }
      });
    },
    // Saving Functionality
    async submitForm(_redirect) {
      if (this.newProject && this.projectId <= this.latestJob.projectId)
        alert(
          `Project Id must be larger than the lastest project which is ${this.latestJob.projectId}`
        );
      if (
        this.projectId &&
        this.projectName &&
        this.contact &&
        this.phone &&
        this.poNumber &&
        this.email
      ) {
        try {
          this.loading = true;
          // GEO Hash
          let geohash = "";
          let geopoint = null;
          let _address = this.addressObj;
          let _formattedAddress = "";

          if (typeof this.address === "string")
            _formattedAddress = this.address;

          // if(_address && _address.route) _formattedAddress = this.formatAddress(_address)

          if (!this.address && !this.address.latitude) {
            _formattedAddress = this.overrideAddress;
            _address = null;
            geohash = "";
            geopoint = null;
          }

          if (this.editedAddress && this.address && this.address.latitude) {
            _formattedAddress = this.formatAddress(this.address);
            const lat = this.address.latitude;
            const lng = this.address.longitude;
            console.log("Address at addreess", this.address);
            console.log("Address at geo", this.address.latitude);
            console.log("Address at geo longitude", this.address.longitude);
            _address = this.address;
            geohash = geofire.geohashForLocation([lat, lng]);
            // GEO point
            geopoint = setGeoPoint({
              latitude: this.address.latitude,
              longitude: this.address.longitude,
            });
          }

          let newArray = [];
          // Loop Jobs and save
          for (let i = 0; i < this.jobs.length; i++) {
            const estStart = new Date(this.estimatedStartDate)
            const estEnd = new Date(this.estimatedEndDate)
            let payload = {
              // Client
              // formattedAddress: _formattedAddress,
              client: this.client,
              active: this.active,
              // Customer
              company: this.customer.company,
              phone: this.phone,
              email: this.email,
              callFrequency: this.callFrequency,
              contact: this.contact,
              poNumber: this.poNumber,
              region: this.region,
              projectType: this.projectType,
              estimatedStartDate: getTimeStamp(estStart),
              estimatedEndDate: getTimeStamp(estEnd),
              customerId: this.customer.id,
              // Project Specific
              // address: _address,
              projectId: this.projectId,
              projectName: this.projectName,
              geohash,
              geopoint,
              // Job specific
              status: this.jobs[i].status,
              contractor: this.jobs[i].contractor,
              meters: this.jobs[i].meters,
              mixCode: this.jobs[i].mixCode,
              accepted: this.jobs[i].accepted,

              requirements: this.jobs[i].requirements,
              pourLocation: this.jobs[i].pourLocation || "",
              notesForTester: this.jobs[i].notesForTester || "",
              notes: this.jobs[i].notes,
              updated: new Date(),
            };

            let projectPayload = {
              client: this.client,
              active: this.active,
              // Customer
              company: this.customer.company,
              phone: this.phone,
             
              email: this.email,
              callFrequency: this.callFrequency,
              contact: this.contact,
              poNumber: this.poNumber,
              region: this.region,
              projectType: this.projectType,
              estimatedStartDate: getTimeStamp(estStart),
              estimatedEndDate: getTimeStamp(estEnd),
              customerId: this.customer.id,
              // Project Specific
              // address: _address,
              projectId: this.projectId,
              projectName: this.projectName,
              geohash,
              geopoint,
            }

            if (this.newProject) {
              payload["created"] = new Date();
              projectPayload["created"] = new Date();
              // payload['lastCalled'] = new Date()
            }

            if (this.editedAddress || this.address != "") {
              // alert()
              this.$set(payload, "address", _address);
              this.$set(projectPayload, "address", _address);
              this.$set(payload, "formattedAddress", _formattedAddress);
              this.$set(projectPayload, "formattedAddress", _formattedAddress);
            }

            if (this.jobs[i].published)
              payload["published"] = this.jobs[i].published;

            if (this.jobs[i].date)
              (payload["date"] = getTimeStamp(new Date(this.jobs[i].date)))

              payload['accepted'] =  this.jobs[i].accepted || false;

            let a;
            if (this.jobs[i].id) {
              // alert(this.jobs[i].id)
              a = await putFire({
                collection: "jobs",
                doc: this.jobs[i].id,
                body: payload,
              });

            } else {
              a = await postFire({
                collection: "jobs",
                body: payload,
              });
            }

            const p = {
                collection: "projects",
                doc: JSON.stringify(this.projectId),
                body: projectPayload,
              }
            console.log("p", p)
            try{
            await setFire(p);
            } catch(e){
              console.log("Failed to save project", e)
            }

            if (a.date && a.date.seconds)
              a.date = new Date(a.date.seconds * 1000).toISOString();
            newArray.push(a);
          }

          if (this.newProject) {
            this.client = this.clients[0];
            this.customer = null;
            this.jobs = [this.schema];
            this.projectName = "";
            this.projectId = this.latestJob.projectId + 1;
            this.status = "";
            this.callFrequency = "";
            this.contact = "";
            this.poNumber = "";
            this.region = "",
            this.projectType = "",
            this.estimatedStartDate = "";
            this.estimatedEndDate = "";
            this.phone = "";
            this.email = "";
            this.search = "";
            this.projects = [];
            this.address = "";
            this.$refs.autocomplete.clear();
            this.editMode = null;
          }

          if (_redirect) this.$router.back(-1);
        } catch (e) {
          alert("Something went wrong");
          console.log("Error", e);
        }
      } else {
        alert(
          "Required fields - Project Id, ProjectName, Contact, Phone, Email"
        );
      }
    },

    async testSaveProjects(){

      const arr = [
  {
    "active": true,
    "address": {
      "administrative_area_level_1": "QLD",
      "administrative_area_level_2": "City of Gold Coast",
      "country": "Australia",
      "latitude": -28.0208868,
      "locality": "Broadbeach",
      "longitude": 153.4332832,
      "postal_code": "4218",
      "route": "Broadbeach Boulevard",
      "street_number": "21"
    },
    "callFrequency": "never",
    "client": "KCT",
    "company": "GLENQ BROADBEACH",
    "contact": "JUSTIN",
    "customerId": "1wjnPMSFTDeNjesmP8vx",
    "email": "justin.swinnerton@glenq.com.au",
    "estimatedEndDate": "2024-12-31T11:00:00+11:00",
    "estimatedStartDate": "2025-01-06T11:00:00+11:00",
    "formattedAddress": "21 Broadbeach Boulevard Broadbeach, QLD Australia 4218",
    "geohash": "",
    "geopoint": null,
    "phone": "+61402398881",
    "poNumber": "PO",
    "projectId": 1811,
    "projectName": "NO. 21",
    "projectType": "BUILDING",
    "region": "SOUTH GC"
  },
  {
    "active": true,
    "address": {
      "administrative_area_level_1": "QLD",
      "administrative_area_level_2": "City of Gold Coast",
      "country": "Australia",
      "latitude": -28.1718272,
      "locality": "Coolangatta",
      "longitude": 153.5387856,
      "postal_code": "4225",
      "route": "Carmichael Close",
      "street_number": "4"
    },
    "callFrequency": "never",
    "client": "KCT",
    "company": "NUCON (QC JOBS -NO EXTRA CHARGES)",
    "contact": "MARK",
    "created": "2025-01-06T13:00:29+11:00",
    "customerId": "LNoUyxqM262DYFUtm0Te",
    "email": "mark.gaddes@nucrush.com.au",
    "estimatedEndDate": "2025-08-31T10:00:00+10:00",
    "estimatedStartDate": "2024-08-26T10:00:00+10:00",
    "formattedAddress": "4 Carmichael Close Coolangatta, QLD Australia 4225",
    "geohash": "",
    "geopoint": null,
    "phone": "+61409099178",
    "poNumber": "PO",
    "projectId": 1812,
    "projectName": "JACKAROO P/L CARMICHAEL CL",
    "projectType": "CIVIL",
    "region": "SOUTH GC"
  },
  {
    "active": true,
    "address": {
      "administrative_area_level_1": "NSW",
      "administrative_area_level_2": "Tweed Shire Council",
      "country": "Australia",
      "latitude": -28.1968801,
      "locality": "Bilambil Heights",
      "longitude": 153.4932992,
      "postal_code": "2486",
      "route": "Walmsleys Road",
      "street_number": "57"
    },
    "callFrequency": "never",
    "client": "",
    "company": "NUCON (QC JOBS -NO EXTRA CHARGES)",
    "contact": "MARK",
    "created": "2025-01-06T13:22:03+11:00",
    "customerId": "LNoUyxqM262DYFUtm0Te",
    "email": "mark.gaddes@nucrush.com.au",
    "estimatedEndDate": "2025-01-31T11:00:00+11:00",
    "estimatedStartDate": "2025-01-06T11:00:00+11:00",
    "formattedAddress": "57 Walmsleys Road Bilambil Heights, NSW Australia 2486",
    "geohash": "r6vpsffmm0",
    "geopoint": {
      "latitude": -28.1968801,
      "longitude": 153.4932992
    },
    "phone": "+61409099178",
    "poNumber": "PO",
    "projectId": 1813,
    "projectName": "CASH SALES - JGH",
    "projectType": "CIVIL",
    "region": "SOUTH GC"
  },
  {
    "active": true,
    "address": {
      "administrative_area_level_1": "QLD",
      "administrative_area_level_2": "Ipswich City",
      "country": "Australia",
      "latitude": -27.6740609,
      "locality": "Ripley",
      "longitude": 152.7852849,
      "postal_code": "4306",
      "route": "Binnies Road",
      "street_number": "33"
    },
    "callFrequency": "never",
    "client": "",
    "company": "THALLON MOLE CONCRETE",
    "contact": "TOM",
    "created": "2025-01-07T09:10:38+11:00",
    "customerId": "EiPCdUFL6lNbIZxc6MRk",
    "email": "EMAIL",
    "estimatedEndDate": "2025-07-31T10:00:00+10:00",
    "estimatedStartDate": "2025-01-06T11:00:00+11:00",
    "formattedAddress": "33 Binnies Road Ripley, QLD Australia 4306",
    "geohash": "r7hdsdnfgj",
    "geopoint": {
      "latitude": -27.6740609,
      "longitude": 152.7852849
    },
    "phone": "+61448779005",
    "poNumber": "PO",
    "projectId": 1814,
    "projectName": "33 Binnies Road",
    "projectType": "CIVIL",
    "region": "WEST BNE"
  },
  {
    "active": true,
    "address": {
      "administrative_area_level_1": "NSW",
      "administrative_area_level_2": "Council of the City of Sydney",
      "country": "Australia",
      "latitude": -33.8688197,
      "locality": "Sydney",
      "longitude": 151.2092955,
      "postal_code": "2000",
      "route": "Wallaby Way",
      "street_number": "42"
    },
    "callFrequency": "",
    "client": "KCT",
    "company": "GROUPLINE CONSTRUCTION",
    "contact": "M",
    "created": "2024-12-22T09:43:59+11:00",
    "customerId": "SOjpcvW7QQTeIZ22oBkX",
    "email": "RONHFNRJ",
    "estimatedEndDate": "2024-12-22T09:43:36+11:00",
    "estimatedStartDate": "2024-12-22T09:43:36+11:00",
    "formattedAddress": "42 Wallaby Way, Sydney, NSW Australia",
    "phone": "043048293",
    "projectId": 1815,
    "poNumber": "",
    "projectName": "TTTTT",
    "projectType": ""
  }
]

arr.forEach(i => {
  setFire({
    collection: "projects",
    doc: i.projectId.toString(),
    body: i
  })
})

    },

    // Get projects when a client changes
    async getProjectById(override) {
      console.log("this.customer", this.customer);
      const objects = await getProjects({ companyName: this.customer.company });

      const uniqueObjects = objects.filter((obj, index, self) => {
        return (
          index ===
          self.findIndex((otherObj) => otherObj.projectId === obj.projectId)
        );
      });

      console.log("uniqueObjects", uniqueObjects);
      if (uniqueObjects.length > 0) {
        if (!override) this.projectId = uniqueObjects[0].projectId;

        uniqueObjects.sort((a, b) => {
          const nameA = a.projectName.toUpperCase(); // Convert names to uppercase for case-insensitive comparison
          const nameB = b.projectName.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names must be equal
        });

        this.projects = uniqueObjects;
        if (!override && this.$route.query.jobId)
          this.projectId = this.job.projectId;

        this.selectProject();
      }
    },
    // general get data
    searchCustomers() {
      this.loadingSelect = true;
      // this.customers = [];
      const url =
        "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/search";

      this.axios
        .get(url, {
          params: {
            indexName: "customers",
            filter: null,
            search: this.search,
            twilio: null,
          },
        })
        .then((response) => {
          console.log("response from search", response);
          this.customers = response.data;
          this.loadingSelect = false;
        })
        .catch((e) => {
          this.loadingSelect = false;
          console.log("Error algolia", e);
        });
    },

    getCustomer() {
      return new Promise(async (res, rej) => {
        this.customer = await getFire({
          collection: "customers",
          doc: this.job.customerId,
        });
        res();
      });
    },
    getJob() {
      return new Promise(async (res, rej) => {
        this.job = await getFire({
          collection: "jobs",
          doc: this.$route.query.jobId,
        });
        this.contact = this.job.contact;
        this.poNumber = this.job.poNumber;
        this.phone = this.job.phone;
        this.email = this.job.email;
        this.callFrequency = this.job.callFrequency;
        this.projectId = this.job.projectId;
        this.region = this.job.region;
        this.projectType = this.job.projectType;
        if(this.job.estimatedStartDate) this.estimatedStartDate = new Date(this.job.estimatedStartDate.seconds * 1000).toISOString();
        if(this.job.estimatedEndDate) this.estimatedEndDate = new Date(this.job.estimatedEndDate.seconds * 1000).toISOString();
        res();
      });
    },
    async getContractors() {
      this.contractors = await getContractors();
    },
    // CRUD for jobs
    removeJob(index) {
      if (window.confirm("Are you sure you want to delete this job?")) {
        if (this.jobs[index].id) this.deleteJob(this.jobs[index].id);
        let jobs = JSON.stringify(this.jobs);
        jobs = JSON.parse(jobs);
        jobs.splice(index, 1);
        this.jobs = jobs;

        if (this.jobs.length == 0) this.jobs.push(this.schema);
      }
    },
    async deleteJob(jobId) {
      await deleteFire({ collection: "jobs", doc: jobId });
    },
    addJob() {
      const s = JSON.stringify(this.schema);
      this.jobs.push(JSON.parse(s));
    },
    duplicateJob(job) {
      const s = JSON.stringify(job);
      let _job = JSON.parse(s);
      if (_job.id) _job.id = "";

      _job.testerNotes = "";
      _job.published = false;
      _job.contractor = null;
      this.jobs.push(_job);
    },

    async getTheLatestJob() {
      this.latestJob = await getLatestJob();
    
      console.log("this.latestJob", this.latestJob);
      if (!this.latestJob) this.latestJob = { projectId: 0 };
      this.projectId = this.latestJob.projectId + 1;
      console.log("latestJob", this.latestJob);
    },

    loadGoogleMapsScript() {
      // Check if the script is already loaded
      if (!document.querySelector('script[src="https://maps.googleapis.com/maps/api/js"]')) {
        const script = document.createElement('script');
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAgzzzBwnKAnilSbETwkny6ApTz18jlHr4&libraries=places";
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      }
    }

  },
  
  components: {
    datetime: Datetime,
    VueGoogleAutocomplete,
    CreateCustomer,
    Multiselect,
    NavMenu,
    flatPickr,
  },
  mounted(){
    this.loadGoogleMapsScript();
  },
  async created() {
    // props: ["editJob", "date", "prevDate"],
    this.getTheLatestJob();
    if (this.$route.query.jobId) {
      this.editMode = this.$route.query.jobId;
      await this.getJob();
      await this.getCustomer();
      await this.selectProject();
    } else {
      this.newProject = true;
      const s = JSON.stringify(this.schema);
      this.jobs.push(JSON.parse(s));
    }

    // Load contractors for teser field on each job
    this.getClients();
    this.getContractors();

    // Listeners
    bus.$on("closeCreateCustomer", (data) => {
      this.createCustomer = data.status;
      if (data.customer) {
        this.customer = data.customer;
      }
    });
  },
};
</script>
  
  <style lang="postcss">
@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 128, 128, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 128, 128, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 128, 128, 0);
  }
}

.pulse-glow {
  animation: pulse-glow 2s infinite;
}

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #01bc38 !important;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #01bc38 !important;
}
</style>
  