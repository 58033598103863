<template>
  <div class="container mx-auto">
    <nav-menu />

    <!-- Tab Navigation -->
    <div class="flex border-b">
      <button :class="['p-4', activeTab === 'leads' ? 'border-b-2 border-blue-500' : 'text-gray-500']"
        @click="activeTab = 'leads'">
        Leads
      </button>
      <button :class="['p-4', activeTab === 'reminders' ? 'border-b-2 border-blue-500' : 'text-gray-500']"
        @click="activeTab = 'reminders'">
        Reminders
      </button>
    </div>

    <!-- Create Lead Button -->
    <div v-if="activeTab === 'leads'" class="w-full flex justify-between items-center p-4">
      <div >
    <select v-model="filterLeads" class="w-full h-12 border border-gray-200 px-2 rounded">
      <option value="all">Show All</option>
      <option value="pending">Pending</option>
      <option value="lost">Lost</option>
      <option value="won">Won</option>
    </select>
  </div>
  <div class="flex justify-between items-center">
    <outlook class="mr-2" />
    <button @click="openModal(null)" class="bg-primary whitespace-nowrap text-white px-4 py-2 rounded">
      Create Lead
    </button>
  </div>


    </div>

    <!-- Leads Table (Desktop) -->
    <div v-if="activeTab === 'leads'" class="w-full overflow-x-auto hidden md:block p-4">
      <table class="table-auto w-full border-collapse border border-gray-300">
        <thead>
          <tr class="bg-gray-800 text-white">
            <th class="border border-gray-300 px-4 py-2">Client</th>
            <th class="border border-gray-300 px-4 py-2">Customer</th>
            <th class="border border-gray-300 px-4 py-2">Phone</th>
            <th class="border border-gray-300 px-4 py-2">Email</th>
            <th class="border border-gray-300 px-4 py-2">Address</th>
            <th class="border border-gray-300 px-4 py-2 whitespace-nowrap">Est Start Date</th>
            <th class="border border-gray-300 px-4 py-2">Meters</th>
            <th class="border border-gray-300 px-4 py-2">Notes</th>
            <th class="border border-gray-300 px-4 py-2">Status</th>
            <th class="border border-gray-300 px-4 py-2">Reminder</th>
            <th class="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="lead in filteredLeads" :key="lead.id" :class="{'bg-green-100': lead.status === 'won', 'bg-red-100': lead.status === 'lost'}">

            <td class="border border-gray-300 px-4 py-2">{{ lead.client }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ lead.customer?.company }}</td>
            <td class="border border-gray-300 px-4 py-2 whitespace-nowrap text-xs text-blue-500 cursor-pointer hover:underline"><a :href="'tel:'+ lead.phone">{{ lead.phone }}</a></td>
            <td class="border border-gray-300 px-4 py-2 whitespace-nowrap text-xs text-blue-500 cursor-pointer hover:underline"><a :href="'mailto:'+ lead.email">{{ lead.email }}</a></td>
            <td class="border border-gray-300 px-4 py-2 whitespace-nowrap text-xs">{{ lead.formattedAddress }}</td>
            <td class="border border-gray-300 px-4 py-2 whitespace-nowrap text-xs">{{ lead.estimatedStartDate }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ lead.approxM3 }}</td>
            <td class="border border-gray-300 px-4 py-2"><div class="w-96 text-xs text-gray-700 text-left">{{ lead.information }}</div></td>
            
            <td class="border border-gray-300 px-4 py-2">
          <select v-model="lead.status" @change="handleStatusChange(lead)" class="border rounded px-2 py-1">
            <option value="pending">Pending</option>
            <option value="won">Won</option>
            <option value="lost">Lost</option>
          </select>
        </td>
        
            <td class=" border border-gray-300"> <button @click="openReminderModal(lead)" class="whitespace-nowrap bg-yellow-500 text-white px-2 py-1 rounded mx-2">
              Add Reminder
            </button></td>
            <td class=" border border-gray-300">
              <div class="flex">
              <button @click="openModal(lead)" class="bg-blue-500 text-white px-2 py-1 rounded ml-2">Edit</button>
          <button @click="confirmRemoveLead(lead)" class="bg-red-500 text-white px-2 py-1 rounded ml-2">Delete</button>
              <button @click="openAddProjectModal(lead)" class="whitespace-nowrap bg-green-500 text-white px-2 py-1 rounded ml-2">
    Add Project
  </button></div>
            </td>

           

          </tr>
        </tbody>
      </table>
    </div>


    <!-- Leads Cards (Mobile) -->
    <div v-if="activeTab === 'leads'" class="block md:hidden p-4">
  <div v-for="lead in leads" :key="lead.id" class="border rounded-lg p-4 mb-4 shadow-sm">
    <h3 class="font-bold text-lg mb-2">{{ lead.projectName }}</h3>
    <p class="text-sm text-gray-600 mb-1">Client: {{ lead.client }}</p>
    <p class="text-sm text-gray-600 mb-1">Customer: {{ lead.customer?.company }}</p>
    <p class="text-sm text-gray-600 mb-1">Phone: 
      <a :href="'tel:'+ lead.phone" class="text-blue-500 underline">{{ lead.phone }}</a>
    </p>
    <p class="text-sm text-gray-600 mb-1">Email: 
      <a :href="'mailto:'+ lead.email" class="text-blue-500 underline">{{ lead.email }}</a>
    </p>
    <p class="text-sm text-gray-600 mb-1">Address: {{ lead.formattedAddress }}</p>
    <p class="text-sm text-gray-600 mb-1">Est Start Date: {{ lead.estimatedStartDate }}</p>
    <p class="text-sm text-gray-600 mb-1">Meters: {{ lead.approxM3 }}</p>
    <p class="text-sm text-gray-600 mb-2">Notes: {{ lead.information }}</p>

    <div class="mb-2">
      <label class="block text-sm text-gray-600 mb-1">Status:</label>
      <select v-model="lead.status" @change="handleStatusChange(lead)" class="border rounded px-2 py-1 w-full">
        <option value="pending">Pending</option>
        <option value="won">Won</option>
        <option value="lost">Lost</option>
      </select>
    </div>

    <div class="flex flex-wrap items-center mt-2 space-x-2">
      <button @click="openModal(lead)" class="bg-blue-500 text-white px-3 py-1 rounded">Edit</button>
      <button @click="openReminderModal(lead)" class="bg-yellow-500 text-white px-3 py-1 rounded">Add Reminder</button>
      <button @click="confirmRemoveLead(lead)" class="bg-red-500 text-white px-3 py-1 rounded">Delete</button>
      <button @click="openAddProjectModal(lead)" class="bg-green-500 text-white px-3 py-1 rounded">Add Project</button>
    </div>
  </div>
</div>

    <!-- Reminders Tab -->
    <div v-if="activeTab === 'reminders'">
      <div class="p-4">
        <h2 class="text-xl font-bold mb-4">Reminders</h2>
        <div class="w-full hidden md:flex">
        <table class=" table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr class="bg-gray-200">
              <th class="border border-gray-300 px-4 py-2">Lead</th>
              <th class="border border-gray-300 px-4 py-2">Date</th>
              <th class="border border-gray-300 px-4 py-2">Subject</th>
              <th class="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reminder in reminders" :key="reminder.id">
              <td class="border border-gray-300 px-4 py-2">{{ reminder.leadName }}</td>
              <td class="border border-gray-300 px-4 py-2">{{ reminder.date }}</td>
              <td class="border border-gray-300 px-4 py-2">{{ reminder.subject }}</td>
              <td class="border border-gray-300 px-4 py-2">
                <button @click="confirmRemoveReminder(reminder)" class="text-red-500 underline">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

        <div v-if="activeTab === 'reminders'" class="block md:hidden p-4">
  <div v-for="reminder in reminders" :key="reminder.id" class="border rounded-lg p-4 mb-4 shadow-sm">
    <h3 class="font-bold text-lg mb-2">Reminder for: {{ reminder.leadName }}</h3>
    <p class="text-sm text-gray-600 mb-1">Date: {{ reminder.date }}</p>
    <p class="text-sm text-gray-600 mb-2">Subject: {{ reminder.subject }}</p>
    <div class="flex items-center space-x-2">
      <button @click="confirmRemoveReminder(reminder)" class="bg-red-500 text-white px-3 py-1 rounded">Remove</button>
    </div>
  </div>
</div>
      </div>
    </div>

    <!-- Modal for Add Reminder -->
    <div v-if="showReminderModal" class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
      <div class="bg-white rounded-lg shadow-lg p-10 w-96 h-2/3 overflow-y-auto">
        <h2 class="text-xl font-bold mb-4">Add Reminder</h2>
        <div class="w-full mb-4">
          <label class="block text-left header text-xs mb-2">Reminder Date</label>
          <flat-pickr class="text-xs w-full bg-gray-100 h-12 rounded px-3 mb-2" :config="dateConfig"
            v-model="reminder.date"></flat-pickr>
        </div>
        <div class="w-full mb-4">
          <label class="block text-left header text-xs mb-2">Subject</label>
          <input v-model="reminder.subject" type="text" class="w-full p-2 border rounded" />
        </div>
        <button @click="saveReminder()" class="bg-yellow-500 py-4 text-white px-4 py-2 rounded w-full">
          Save Reminder
        </button>
        <button @click="closeReminderModal" class="mt-2 text-red-500 underline w-full text-center">
          Cancel
        </button>
      </div>
    </div>


    <!-- Add Project Modal -->
<div v-if="showAddProjectModal" class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
  
  <div v-if="toggleviewProject" class="relative bg-white rounded-lg shadow-lg p-10 w-96 overflow-y-auto">
    
    <button @click="closeAddProjectModal" class="absolute top-2 right-2 text-gray-500 hover:text-red-500">
      ✖
    </button>

    <div class="text-left"><h2 class="header mb-1">Select a project</h2></div>
    <select v-model="selectedProjectId" class="w-full p-2 h-20 border rounded">
    <option disabled value="">Select a project</option>
    <option v-for="project in filteredProjects" :key="project.id" :value="project.id">
      {{ project.projectName }}
    </option>
  </select>

  <button @click="navigateToProject" class="mt-4 bg-secondary text-white px-4 py-2 rounded w-full">
      View Project
    </button>

     <div class="mt-4 py-4 border-t border-gray-200">
      <div class="text-left"><h2 class="header mb-1">Or would you like to create a new project</h2></div>
      <button @click="toggleviewProject = false" class="bg-primary text-white px-4 py-2 rounded w-full">
      Create a new project
    </button>
     </div>

      </div>

  <div v-if="!toggleviewProject" class="bg-white rounded-lg shadow-lg p-10 w-96 h-2/3 overflow-y-auto">
    <h2 class="text-xl font-bold mb-4">Add Project</h2>
    

    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Client</label>
      <select v-model="newProject.client" class="border border-gray-200 rounded flex-grow h-10 bg-white outline-none px-2 text-gray-600 w-full">
        <option :value="c" v-for="c in clients" :key="c">{{ c }}</option>
      </select>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Customer</label>
      <multiselect label="company" track-by="company" @search-change="searchCustomers" :loading="loadingSelect"
        v-model="newProject.customerId" :options="customers" :optionsLimit="300000"></multiselect>
    </div>

    <!-- Project Form (Prepopulated with Lead Properties) -->
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Project Name</label>
      <input v-model="newProject.projectName" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Address</label>
      <vue-google-autocomplete
        :options="autocompleteOptions"
        id="project-map"
        v-model="newProject.formattedAddress"
        country="au"
        ref="projectAutocomplete"
        v-on:placechanged="getProjectAddressData"
        placeholder="Project Address"
        classname="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
      ></vue-google-autocomplete>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Estimated Start Date</label>
      <flat-pickr v-model="newProject.estimatedStartDate" :config="config" class="w-full bg-gray-100 h-12 rounded px-3" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Estimated End Date</label>
      <flat-pickr v-model="newProject.estimatedEndDate" :config="config" class="w-full bg-gray-100 h-12 rounded px-3" />
    </div>
    
    <!-- Add Remaining Fields -->
    <div v-for="field in newProjectFields" :key="field.key" class="w-full mb-4">
      <label :for="field.key" class="block text-left header text-xs mb-2">{{ field.label }}</label>
      <input v-model="newProject[field.key]" :type="field.type" class="w-full p-2 border rounded" />
    </div>

    <button @click="saveProject" class="bg-blue-500 text-white px-4 py-2 rounded w-full">
      Save Project
    </button>
    <button @click="closeAddProjectModal" class="mt-2 text-red-500 underline w-full text-center">
      Cancel
    </button>
  </div>
</div>



    <!-- Modal -->
    <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
     
 <!-- Project form  -->
      <div  class="bg-white rounded-lg shadow-lg p-10 relative w-96 h-2/3 overflow-y-auto">
   
        <button @click="showModal = false" class="absolute top-2 right-2 text-gray-500 hover:text-red-500">
      ✖
    </button>

        <h2 class="text-xl font-bold mb-4">
      {{ editingLead ? 'Edit Lead' : 'Create Lead' }}
    </h2>
    <!-- Form Fields -->
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Client</label>
      <select v-model="lead.client" class="rounded flex-grow h-10 bg-white border border-gray-200 outline-none px-2 text-gray-600 w-full">
        <option :value="c" v-for="c in clients" :key="c">{{ c }}</option>
      </select>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Customer</label>
      <multiselect label="company" track-by="company" @search-change="searchCustomers" :loading="loadingSelect"
        v-model="lead.customer" :options="customers" :optionsLimit="300000"></multiselect>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Contact</label>
      <input v-model="lead.contact" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Phone</label>
      <input v-model="lead.phone" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Email</label>
      <input v-model="lead.email" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Address</label>
      <vue-google-autocomplete
        :options="autocompleteOptions"
        id="map"
        v-model="lead.formattedAddress"
        country="au"
        ref="autocomplete"
        v-on:placechanged="getAddressData"
        classname="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
        placeholder="Project Address"
      ></vue-google-autocomplete>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Estimated Start Date</label>
      <flat-pickr class="text-xs w-full bg-gray-100 h-12 rounded px-3 mb-2" :config="config"
        v-model="lead.estimatedStartDate"></flat-pickr>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Estimated End Date</label>
      <flat-pickr class="text-xs w-full bg-gray-100 h-12 rounded px-3 mb-2" :config="config"
        v-model="lead.estimatedEndDate"></flat-pickr>
    </div>
   
    <!-- <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">PO Number</label>
      <input v-model="lead.poNumber" type="text" class="w-full p-2 border rounded" />
    </div> -->
    
    <!-- <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Call Frequency</label>
      <input v-model="lead.callFrequency" type="text" class="w-full p-2 border rounded" />
    </div> -->
    <!-- <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Project Type</label>
      <input v-model="lead.projectType" type="text" class="w-full p-2 border rounded" />
    </div> -->
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Approx M3</label>
      <input v-model="lead.approxM3" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Additional Information</label>
      <textarea v-model="lead.information" class="w-full p-2 border rounded"></textarea>
    </div>

    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Status</label>
      <select v-model="lead.status" @change="handleStatusChange(lead)" class="w-full p-2 border rounded">
        <option value="won">Pending</option>
        <option value="won">Won</option>
        <option value="lost">Lost</option>
      </select>
    </div>

    <button @click="saveLead" class="bg-blue-500 text-white px-4 py-2 rounded w-full">
      {{ editingLead ? 'Update Lead' : 'Create Lead' }}
    </button>
    <button @click="closeModal" class="mt-2 text-red-500 underline w-full text-center">
      Cancel
    </button>
  </div>
</div>

<div v-if="showLossReasonModal" class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
  <div class="relative bg-white rounded-lg shadow-lg p-10 w-96">
    <button @click="showLossReasonModal = false" class="absolute top-2 right-2 text-gray-500 hover:text-red-500">
      ✖
    </button>
    <h2 class="text-xl font-bold mb-4">Loss Reason</h2>
    <textarea v-model="lossReason" class="w-full p-2 border rounded" placeholder="Enter the reason for loss"></textarea>
    <button @click="saveLossReason" class="bg-red-500 text-white px-4 py-2 rounded w-full mt-4">
      Save Reason
    </button>
  </div>
</div>


  </div>
</template>

<script>
import Outlook from "@/views/Outlook.vue";
import CreateCustomer from "@/components/cards/createCustomer.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import NavMenu from "@/components/headers/navMenu";
import { bus } from "../main";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {
  putFire,
  postFire,
  getContractors,
  getJobsByProjectId,
  getFire,
  deleteFire,
  getProjects,
  getLatestJob,
  getClients,
  getLeads,
  getCrmReminders,
  setFire,
  getProjectsByClient
} from "../firestore/utils";
import { getTimeStamp, setGeoPoint } from "../firebase";
const geofire = require("geofire-common");
import Multiselect from "vue-multiselect";
import { auth } from "../firebase";
import axios from 'axios';

export default {
  components: {
    Multiselect,
    VueGoogleAutocomplete,
    flatPickr,
    Outlook,
    Datetime,
    NavMenu
  },
  data() {
    return {
      filterLeads:"all",
      lossReason: "",
    showLossReasonModal: false,
      toggleviewProject: true,
      showAddProjectModal: false,
      selectedLead: null, // The lead selected for adding a project
      selectedProjectId: "", // Holds the selected project ID
      projects: [], // All projects fetched from Firestore
      newProject: {
        client: null,
        customerId: null,
        projectName: "",
        formattedAddress: "",
        address: null,
        estimatedStartDate: new Date(),
        estimatedEndDate: new Date(),
        active: true, // Always true for new projects
      },
      newProjectFields: [
        { key: "poNumber", label: "PO Number", type: "text" },
        { key: "projectType", label: "Project Type", type: "text" },
        { key: "callFrequency", label: "Call Frequency", type: "text" },
        { key: "contact", label: "Contact", type: "text" },
        { key: "email", label: "Email", type: "email" },
        { key: "phone", label: "Phone", type: "text" },
        { key: "company", label: "Company", type: "text" },
      ],
      showReminderModal: false,
      activeTab: 'leads',
      config: {
        enableTime: true,
        altFormat: "d-m-Y H:i",
        altInput: true,
        allowInput: false,
      },
      reminder: {
        leadId: null,
        leadName: "",
        date: new Date(),
        subject: "",
      },

      loading: true,
      clients: [],
      customers: [],
      showModal: false,
      editingLead: null,
      leads: [],
      lead: {
        client: null,
        customer: null,
        projectName: "",
        projectType: "",
        poNumber: "",
        contact: "",
        approxM3: "",
        status: "",
        phone: "",
        email: "",
        callFrequency: "",
        projectType: "",
        information: "",
        formattedAddress: "",
        address: null,
        estimatedStartDate: new Date(),
        estimatedEndDate: new Date(),
        quotedDate: new Date(),
        created: null,
        geohash: null,
        geopoint: null
      },

      autocompleteOptions: {
        types: ["geocode"],
      },
      overrideAddress: "",
      myAddressData: null,
      editedAddress: false,
      loadingSelect: false,
      dateConfig: {
        enableTime: true,
        altFormat: "d-m-Y H:i",
      },
    };
  },
  computed: {
    filteredLeads() {
    if (this.filterLeads === "all") {
      return this.leads;
    }
    return this.leads.filter(lead => lead.status === this.filterLeads);
  },
    // Filter projects based on the selected lead's customerId
    filteredProjects() {
      if (!this.selectedLead?.customer?.id) return [];
      return this.projects.filter(project => project.customerId === this.selectedLead.customer.id);
    },
  },
  methods: {
    async handleStatusChange(lead) {
    if (lead.status === "Won") {
      this.openAddProjectModal(lead);
    } else if (lead.status === "Lost") {
      this.openLossReasonModal(lead);
    } else {
      // Update lead without additional modals
      await putFire({ collection: "leads", doc: lead.id, body: { status: lead.status } });
    }
  },
  openLossReasonModal(lead) {
    this.selectedLead = lead;
    this.showLossReasonModal = true; // Add this variable to `data()`
  },
  async saveLossReason() {
    if (this.lossReason) {
      await putFire({
        collection: "leads",
        doc: this.selectedLead.id,
        body: { status: "Lost", lossReason: this.lossReason },
      });
      this.showLossReasonModal = false;
      this.lossReason = ""; // Clear the loss reason field
    }
  },
    async openAddProjectModal(lead) {
      this.selectedLead = lead; // Set the selected lead
      // Prepopulate new project fields with lead properties
      this.newProject = {
        client: lead.client,
        customerId: lead.customer,
        projectName: lead.projectName,
        formattedAddress: lead.formattedAddress || "",
        address: lead.address || null,
        estimatedStartDate: new Date(),
        estimatedEndDate: new Date(),
        poNumber: lead.poNumber || "",
        projectType: lead.projectType || "",
        callFrequency: lead.callFrequency || "",
        contact: lead.contact || "",
        email: lead.email || "",
        phone: lead.phone || "",
        company: lead.customer?.company || "",
        active: true, // New projects are always active
      };
      await this.fetchProjects();
      this.showAddProjectModal = true;
    },
    async fetchProjects() {
      try {
        this.projects = await getProjectsByClient({customerId: this.selectedLead.customer.id}); // Replace with your Firestore utility function
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    },

    async navigateToProject() {
    if (this.selectedProjectId) {
      const _project = getFire({collection: "projects", doc: this.selectedProjectId})
      this.saveEmptyJob(_project, this.selectedProjectId)

      const jobs = await getJobsByProjectId(this.selectedProjectId)
      this.$router.push({name: 'Jobs', query: {jobId: jobs[0].id}})
      // Perform any additional logic here, such as navigating to the project
    }
  },


    closeAddProjectModal() {
      this.showAddProjectModal = false;
      this.toggleviewProject = true;
    },
    getProjectAddressData(addressData) {
      this.newProject.address = addressData;
      this.newProject.formattedAddress = addressData.formatted_address;
    },
    async saveProject() {

      const latestJob = await getLatestJob()
      const newP = latestJob.projectId + 1
      setFire({
        collection: "projects",
        doc: newP.toString(),
        body: { ...this.newProject, active: true, created: new Date(), customerId: this.selectedLead.customer?.id },
      }).then(() => {
        this.saveEmptyJob(this.newProject, newP)
       
      }).then(() => {
        this.toggleviewProject = true;
      })
    },

    async saveEmptyJob(lead, newP){

      // Convert newP to a number if it's a string
  if (typeof newP === "string") {
    newP = Number(newP);

    // Check if conversion to number was successful
    if (isNaN(newP)) {
      throw new Error(`Invalid value for newP: ${newP}. Must be a valid number.`);
    }
  }

  // Ensure newP is a number
  if (typeof newP !== "number") {
    throw new Error(`newP must be a number, but received: ${typeof newP}`);
  }
  
      let payload = {
              // Client
              formattedAddress: this.selectedLead.formattedAddress || "",
              client: lead.client || "",
              active: true,
              // Customer
              company: lead.company || "",
              phone: lead.phone || "",
              email: lead.email || "",
              callFrequency: lead.callFrequency || "",
              contact: lead.contact || "",
              poNumber: lead.poNumber || "",
              region: lead.region || "",
              projectType: lead.projectType || "",
              estimatedStartDate: "",
              estimatedEndDate: "",
              customerId: this.selectedLead.customer.id || "",
              // Project Specific
              address: this.selectedLead.address || null,
              projectId: newP,
              projectName: lead.projectName || "",
              geohash: this.selectedLead.geohash || "",
              geopoint: this.selectedLead.geopoint || null,
              // Job specific
              status: "ON-CALL",
              contractor: null,
              meters: this.selectedLead.approxM3,
              mixCode: "",
              accepted: false,
              requirements: "",
              pourLocation:  "",
              notesForTester:  "",
              notes: this.selectedLead.information || "",
              updated: new Date(),
            };

            console.log("payload jobs", payload)

            const _job = await postFire({
                collection: "jobs",
                body: payload,
              });

            this.$router.push({name: 'Jobs', query: {jobId: _job.id}})
    },
    async confirmRemoveLead(lead) {
      const confirmed = confirm(`Are you sure you want to remove the lead "${lead.projectName}"?`);
      if (confirmed) {
        await this.removeLead(lead);
      }
    },
    async removeLead(lead) {
      try {
        await deleteFire({ collection: "leads", doc: lead.id });
        this.getAllLeads(); // Refresh the leads list
        alert("Lead removed successfully.");
      } catch (error) {
        console.error("Error removing lead:", error);
        alert("Failed to remove lead.");
      }
    },
    async confirmRemoveReminder(reminder) {
      const confirmed = confirm(`Are you sure you want to remove the reminder "${reminder.subject}"?`);
      if (confirmed) {
        await this.removeReminder(reminder);
      }
    },
    async removeReminder(reminder) {
      try {
        await deleteFire({ collection: "crmReminders", doc: reminder.id });
        this.getAllReminders(); // Refresh the reminders list
        alert("Reminder removed successfully.");
      } catch (error) {
        console.error("Error removing reminder:", error);
        alert("Failed to remove reminder.");
      }
    },
    formatToISO(date) {
      if (typeof date === 'string' && !isNaN(Date.parse(date))) {
        // Already a valid date string
        return new Date(date).toISOString();
      } else if (date instanceof Date) {
        // It's a Date object
        return date.toISOString();
      } else {
        // Invalid date
        console.error('Invalid date format:', date);
        return null;
      }
    },
    async createEventCalender(accessToken, reminder) {

      const formattedDate = this.formatToISO(this.reminder.date);
      if (!formattedDate) {
        console.error("Invalid reminder date. Cannot create event.");
        return;
      }
      
      const notes = `
      Address: ${reminder.lead.address} \n 
      projectName: ${reminder.lead.projectName} \n 
      approxM3: ${reminder.lead.address} \n 
      client: ${reminder.lead.address} \n 
      contact: ${reminder.lead.contact} \n 
      phone: ${reminder.lead.phone} \n 
      email: ${reminder.lead.email} \n 
      customer: ${reminder.lead.customer.company} \n 
      quotedDate: ${reminder.lead.quotedDate} \n 
      startDate: ${reminder.lead.startDate} \n 
      `

      const _event = {
        subject: this.reminder.subject,
        start: formattedDate,
        end: formattedDate,
        reminderMinutesBeforeStart: 15,
        accessToken: accessToken,
        notes: notes
      }

      console.log("_event", _event)

      try {
        const response = await axios.post('https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/calendar/event', _event);
        console.log(response.data);
      } catch (error) {
        console.error('Error creating event:', error.message);
      }
    },
    openReminderModal(lead) {
      this.reminder.leadId = lead.id;
      this.reminder.lead = lead;
      this.reminder.leadName = lead.projectName;
      this.showReminderModal = true;
    },
    closeReminderModal() {
      this.showReminderModal = false;
    },
    async saveReminder() {
      const payload = {
        collection: "crmReminders",
        body: { ...this.reminder, created: new Date() },
      };
      await postFire(payload);
      // Save to calendar
      const _user = await this.getCurrentUser()
      console.log("_user", _user)
      const userDoc = await getFire({collection: "users", doc: _user.uid})
      this.createEventCalender(userDoc.outlookToken, this.reminder);

      this.closeReminderModal();
      this.getAllReminders();
    },
    async getAllReminders() {
      this.reminders = []
      this.reminders = await getCrmReminders();
    },
    async getCurrentUser() {
      return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe(); // Stop listening to further changes once resolved
          if (user) {
            resolve(user);
          } else {
            reject(new Error("No user is signed in."));
          }
        });
      });
    },
    onInput(a) {
      // this.autoSave()
      this.overrideAddress = a.newVal;
      this.editedAddress = true;
      // this.address = this.overrideAddress;
    },
    async getAllClients() {
      this.clients = await getClients();
    },
    async getAllLeads() {
      this.leads = await getLeads();
    },
    searchCustomers() {
      this.loadingSelect = true;
      // this.customers = [];
      const url =
        "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/search";

      this.axios
        .get(url, {
          params: {
            indexName: "customers",
            filter: null,
            search: this.search,
            twilio: null,
          },
        })
        .then((response) => {
          console.log("response from search", response);
          this.customers = response.data;
          this.loadingSelect = false;
        })
        .catch((e) => {
          this.loadingSelect = false;
          console.log("Error algolia", e);
        });
    },
    getAddressData(addressData) {
      console.log("addressData", addressData)
      this.lead.address = addressData;
      this.myAddressData = addressData;

      this.lead.geohash = geofire.geohashForLocation([addressData.latitude, addressData.longitude]);
      // GEO point
      this.lead.geopoint = setGeoPoint({
        latitude: addressData.latitude,
        longitude: addressData.longitude,
      });

      if (addressData && addressData.route && addressData.street_number) {
        this.lead.formattedAddress = `${addressData.street_number} ${addressData.route}, ${addressData.locality}, ${addressData.administrative_area_level_1} ${addressData.country}`;
      }
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve(); // Script already loaded
        } else {
          const script = document.createElement("script");
          script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAgzzzBwnKAnilSbETwkny6ApTz18jlHr4&libraries=places";
          script.async = true;
          script.defer = true;
          script.onload = () => resolve();
          script.onerror = () => reject("Failed to load Google Maps script.");
          document.head.appendChild(script);
        }
      });
    },
    async saveLead() {
      if (this.editingLead) {
        await putFire({
          collection: "leads",
          doc: this.editingLead.id,
          body: { ...this.lead },
        });
      } else {
        await postFire({
          collection: "leads",
          body: { ...this.lead, created: new Date() },
        });
      }
      this.closeModal();
      this.getAllLeads();
    },
    openModal(lead) {
      if (lead) {
        this.editingLead = lead;
        this.lead = { ...lead };
      } else {
        this.editingLead = null;
        this.lead = {
          client: null,
          customer: null,
          projectName: "",
          contact: "",
          approxM3: "",
          status: "",
          created: null,
        };
      }
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

  },
  async mounted() {
    try {
      await this.loadGoogleMapsScript();
      this.loading = false; // Allow rendering once script is loaded
    } catch (error) {
      console.error(error);
    }
  },
  created() {
    this.getAllClients();
    this.getAllLeads();
    this.getAllReminders();
  },
};
</script>
